import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTranslate } from '@tolgee/react';
import { InviteUserForm } from 'modules/admin/users/InviteUser.model';
import useInviteUSerResolver from 'modules/admin/users/useInviteUserResolver';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useInviteUserMutation } from 'services/endpoints/UsersEndpoints';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { DirectusRoles } from 'shared/model/types';
import { addCatchErrorAlert } from 'shared/reducers/alertSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

//const url = import.meta.env.VITE_DIRECTUS_URL;

type Props = {
  roles: DirectusRoles[];
};

const InviteUserFormPage = ({ roles }: Props) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resolver = useInviteUSerResolver();

  const [inviteUser, { isLoading }] = useInviteUserMutation();

  const defaultRole = roles.find(r => r.name === 'ROLE_USER');

  const endUrl = '/admin/users';

  const isDisabled = isLoading;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<InviteUserForm>({
    defaultValues: {
      email: '',
      role: defaultRole?.id,
    },
    resolver,
  });

  const onSubmit = async (data: InviteUserForm) => {
    try {
      console.log(data);

      await inviteUser({
        ...data,
        invite_url: window.location.origin,
      }).unwrap();

      navigate(endUrl);
    } catch (e) {
      dispatch(addCatchErrorAlert(e));
    }
  };

  return (
    <Box>
      <FormTemplate titlei18n="user.invite" icon={<PersonAddIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('email')}
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  autoFocus
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => {
                const onChange = (event: SelectChangeEvent<string>) => {
                  const value = event.target.value;
                  field.onChange(value);
                };

                return (
                  <FormControl fullWidth>
                    <InputLabel>{t('role')}</InputLabel>
                    <Select
                      label={t('role')}
                      onChange={onChange}
                      value={field.value}
                      fullWidth
                      disabled={isDisabled}
                    >
                      {roles.map(role => (
                        <MenuItem key={role.id} value={role.id}>
                          {t(`roles.${role.name}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </Box>
          <FormButtons cancelUrl={endUrl} disabled={isDisabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default InviteUserFormPage;
