import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { useGetScientificAttachmentsQuery } from 'services/endpoints/ScientificAttachmentEndpoints';
import IconValue from 'shared/components/IconValue';
import { useGetDefaultMRTOptions } from 'shared/material-data-table/defaultMRTable';
import { ScientificAttachmentIcon } from 'shared/model/def/ScientificAttachment.def';
import { ScientificAttachment } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
type Props = {
  selected: number[];
  onChange: (rowSelection: MRT_RowSelectionState) => void;
  tableSx?: SxProps<Theme>;
};

const ScientificAttachmentsSelection = ({ selected, tableSx, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();
  const defaultMRTOptions = useGetDefaultMRTOptions<ScientificAttachment>();
  const columnHelper = createMRTColumnHelper<ScientificAttachment>();

  const { data: datas, isLoading } = useGetScientificAttachmentsQuery({
    sort: 'name',
    limit: 200,
  });

  const data: ScientificAttachment[] = datas ?? [];

  const defaultRowSelection = useMemo(() => {
    return selected.reduce((acc: any, id) => {
      acc[id] = true;
      return acc;
    }, {});
  }, [selected]);

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor('name', {
        header: t('name'),
        //size: 100,
      }),
    ];
    return result;
  }, [t]);

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    initialState: {
      ...defaultMRTOptions.initialState,
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      rowSelection: defaultRowSelection,
    },
    enableTopToolbar: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    positionToolbarAlertBanner: 'top',
    columns,
    data,
    state: {
      isLoading,
    },
    muiTableContainerProps: {
      sx: tableSx,
    },
  });

  const onConfirm = async () => {
    onChange(table.getState().rowSelection);
  };

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <IconValue icon={<ScientificAttachmentIcon />}>
          <Box>{t('selectItem')}</Box>
        </IconValue>
        <Box display="flex">
          <Button size="small" onClick={onConfirm} color="success">
            <CheckIcon />
          </Button>
          <Button size="small" onClick={onCancel} variant="outlined" sx={{ ml: 1 }}>
            <BlockIcon />
          </Button>
        </Box>
      </Box>
      <Box mt={1}>
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};

export default ScientificAttachmentsSelection;
