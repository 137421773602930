import { MenuItem, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { MRT_Header, MRT_RowData } from 'material-react-table';
import { LabelValue, sortByLabel } from 'shared/utils/array-utils';

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  items: LabelValue[];
}

const CustomSelectFilter = <TData extends MRT_RowData>({ header, items }: Props<TData>) => {
  const { t } = useTranslate();

  items.sort(sortByLabel());

  return (
    <TextField
      fullWidth
      margin="none"
      onChange={e => header.column.setFilterValue(e.target.value || undefined)}
      placeholder="Filter"
      select
      value={header.column.getFilterValue() ?? ''}
      variant="standard"
    >
      {/*@ts-ignore*/}
      <MenuItem value={null}>{t('all')}</MenuItem>
      {items.map(i => (
        <MenuItem key={i.value} value={i.value}>
          {i.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomSelectFilter;
