import { List } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import {
  useCreateStructureTutelleMutation,
  useDeleteStructureTutelleMutation,
  useUpdateStructureTutelleMutation,
} from 'services/endpoints/StructureTutelleEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import EditableCard from 'shared/components/Card/EditableCard';
import IconValue from 'shared/components/IconValue';
import TutelleItem from 'shared/components/tutelles/TutelleItem';
import TutellesSelection from 'shared/components/tutelles/TutellesSelection';
import { TutelleIcon } from 'shared/model/def/Tutelle.def';
import { Structure, Tutelle } from 'shared/model/types';

type Props = {
  structure: Structure;
};

const StructureTutelleList = ({ structure }: Props) => {
  const { t } = useTranslate();

  const [createTutelle, { isLoading: isCreating }] = useCreateStructureTutelleMutation();
  const [updateTutelle, { isLoading: isUpdating }] = useUpdateStructureTutelleMutation();
  const [deleteTutelle, { isLoading: isDeleting }] = useDeleteStructureTutelleMutation();

  const tutelles = structure.tutelles ? (structure.tutelles as Tutelle[]) : [];

  const onConfirm = async (
    toAddItems: Partial<Tutelle>[],
    toUpdateItems: Partial<Tutelle>[],
    toDeleteItems: Tutelle[],
  ) => {
    toAddItems.forEach(
      async toAdd =>
        await createTutelle({
          item: {
            ...toAdd,
            structure: structure.id,
          },
        }).unwrap(),
    );
    toUpdateItems.forEach(async item => {
      if (item.id) {
        await updateTutelle({ id: item.id, item }).unwrap();
      }
    });

    if (toDeleteItems.length > 0) {
      await deleteTutelle(toDeleteItems).unwrap();
    }
  };

  const isDisabled = isCreating || isUpdating || isDeleting;

  return (
    <EditableCard
      title={<IconValue icon={<TutelleIcon />}>{t('tutelles.title')}</IconValue>}
      actions={
        <OpenDrawerButton>
          <TutellesSelection tutelles={tutelles} onConfirm={onConfirm} isDisabled={isDisabled} />
        </OpenDrawerButton>
      }
    >
      <List dense>
        {tutelles.map(item => (
          <TutelleItem key={item.id} item={item} />
        ))}
      </List>
    </EditableCard>
  );
};

export default StructureTutelleList;
