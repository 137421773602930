import Box from '@mui/material/Box/Box';
import TextField from '@mui/material/TextField';

import { ResetPasswordForm } from 'modules/user/resetPwd/ResetPwd.model';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useResetPasswordMutation } from 'services/endpoints/UsersEndpoints';
import FormButtons from 'shared/components/form/FormButtons';

import FormTemplate from 'shared/components/form/FormTemplate';
import { useDirectusAppLogoUrl } from 'shared/hooks/directus-hook';
import { addCatchErrorAlert, addSuccessAlert } from 'shared/reducers/alertSlice';

import { useTranslate } from '@tolgee/react';
import { jwtDecode } from 'jwt-decode';
import { Token } from 'modules/admin/users/InviteUser.model';
import useResetPwdResolver from 'modules/user/resetPwd/useResetPwdResolver';
import PasswordI18nStrength from 'shared/components/form/PasswordI18nStrength';
import PasswordTextField from 'shared/components/form/PasswordTextField';
import { useAppDispatch } from 'shared/redux/redux-utils';
import { validatePassword } from 'shared/utils/password-utils';

const ResetPwdPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoUrl = useDirectusAppLogoUrl();
  const { t } = useTranslate();
  const [searchParams] = useSearchParams();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const token = searchParams.get('token');
  const info = token ? jwtDecode<Token>(token) : undefined;

  const resolver = useResetPwdResolver();

  const endUrl = '/';

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      email: info?.email,
      password: '',
      confirmPassword: '',
    },
    resolver,
  });

  if (!token || info?.scope !== 'password-reset') {
    return <Navigate to={endUrl} />;
  }

  const onSubmit = async (data: ResetPasswordForm) => {
    try {
      await resetPassword({
        token,
        password: data.password,
      }).unwrap();
      navigate(endUrl);
      dispatch(addSuccessAlert(t('passwordResetSuccess')));
    } catch (e) {
      dispatch(addCatchErrorAlert(e));
    }
  };

  const currentPwd = watch('password');
  const validPwd = validatePassword(currentPwd, t).length === 0;
  const isDisabled = isLoading || !validPwd;

  return (
    <Box mt={4}>
      <FormTemplate
        titlei18n="resetPassword"
        icon={<img alt="logo" src={`${logoUrl}?key=logo-big`} />}
        errors={errors}
      >
        <Box px={4} mt={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={2}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t('email')}
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordTextField
                    label={t('newPassword')}
                    {...field}
                    type="password"
                    autoComplete="new-password"
                    disabled={isLoading}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    autoFocus
                  />
                )}
              />
            </Box>
            <PasswordI18nStrength password={currentPwd} />
            <Box mt={2}>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <PasswordTextField
                    label={t('confirmPassword')}
                    {...field}
                    type="password"
                    autoComplete="new-password"
                    disabled={isLoading}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />
            </Box>
            <FormButtons cancelUrl={endUrl} disabled={isDisabled} />
          </form>
        </Box>
      </FormTemplate>
    </Box>
  );
};

export default ResetPwdPage;
