import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import usePublicationResolver from 'modules/sheet/structure/publications/usePublicationResolver';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PublicationTypeAutocomplete from 'shared/components/autocomplete/PublicationTypeAutocomplete';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { PublicationFormType, PublicationIcon } from 'shared/model/def/Publication.def';
import { Publication, PublicationType } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  publication: Partial<Publication>;
  disabled?: boolean;
  onSubmit: (team: PublicationFormType) => void;
};

const PublicationForm = ({ publication, disabled = false, onSubmit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resolver = usePublicationResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PublicationFormType>({
    defaultValues: {
      ...publication,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const titleKey = publication.id ? 'publication.edit' : 'publication.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleKey} icon={<PublicationIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              control={control}
              name="pub_date"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={t('publication.pubDate')}
                    value={field.value ? dayjs(field.value) : null}
                    inputRef={field.ref}
                    timezone="UTC"
                    disableFuture
                    onChange={date => {
                      field.onChange(date);
                    }}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        required: !!errors.pub_date,
                        helperText: errors.pub_date?.message,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <PublicationTypeAutocomplete
                    value={value as PublicationType | null}
                    onChange={onChange}
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('title')}
                  {...field}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="web_site"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('webSite')}
                  {...field}
                  error={!!errors.web_site}
                  helperText={errors.web_site?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('content')}
                  multiline
                  rows={10}
                  {...field}
                  error={!!errors.content}
                  helperText={errors.content?.message}
                />
              )}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default PublicationForm;
