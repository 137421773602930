import { useTranslation } from 'react-i18next';

import { Box, FormHelperText } from '@mui/material';
import { useMemo } from 'react';
import { validatePassword } from 'shared/utils/password-utils';

type Props = {
  password: string;
};

const PasswordI18nStrength = ({ password }: Props) => {
  const { t } = useTranslation();

  const result = useMemo(() => {
    if (password.length > 0) {
      return validatePassword(password, t);
    }
    return [];
  }, [password]);

  return (
    <Box>
      {result.map(r => (
        <FormHelperText error key={r.message}>
          {r.message}
        </FormHelperText>
      ))}
    </Box>
  );
};

export default PasswordI18nStrength;
