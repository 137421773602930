import { useTranslation } from 'react-i18next';
import {
  AbstractCancelledIcon,
  AbstractInvitationIcon,
  AbstractOralIcon,
  AbstractPosterIcon,
  AbstractType,
} from 'shared/model/def/abstract.def';

type Props = {
  type: AbstractType | string | undefined | null;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
};

const AbstractTypeIcon = ({ type, fontSize }: Props) => {
  const { t } = useTranslation();

  switch (type) {
    case 'O':
      return <AbstractOralIcon fontSize={fontSize} titleAccess={t('searchFacet.abstract.O')} />;
    case 'P':
      return <AbstractPosterIcon fontSize={fontSize} titleAccess={t('searchFacet.abstract.P')} />;
    case 'OP':
      return (
        <>
          <AbstractOralIcon fontSize={fontSize} titleAccess={t('searchFacet.abstract.O')} />
          <AbstractPosterIcon fontSize={fontSize} titleAccess={t('searchFacet.abstract.P')} />
        </>
      );
    case 'I':
      return (
        <AbstractInvitationIcon fontSize={fontSize} titleAccess={t('searchFacet.abstract.I')} />
      );
    case 'A':
      return (
        <AbstractCancelledIcon fontSize={fontSize} titleAccess={t('searchFacet.abstract.A')} />
      );
  }

  return null;
};

export default AbstractTypeIcon;
