import HistoryIcon from '@mui/icons-material/History';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu/Menu';
import { useTranslate } from '@tolgee/react';
import UserHistoryItem from 'layout/header/UserHistoryItem';
import React, { useState } from 'react';
import { useGetHistories } from 'shared/hooks/historyHook';

const HistoryHeaderMenu = () => {
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const histories = useGetHistories();

  if (histories.length === 0) {
    return null;
  }

  const toShow = histories.slice(0, 10);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="text"
        onClick={handleOpenMenu}
        sx={{
          color: '#aaa',
          marginLeft: 2,
        }}
        startIcon={<HistoryIcon />}
      >
        {t('header.history')}
      </Button>

      <Menu
        sx={{ mt: '2rem' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {toShow.map(h => (
          <UserHistoryItem key={h.id} item={h} afterClick={handleCloseMenu} />
        ))}
      </Menu>
    </>
  );
};

export default HistoryHeaderMenu;
