import { Box, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import useTeamResolver from 'modules/sheet/team/useTeamResolver';
import { MuiTelInput } from 'mui-tel-input';
import { Controller, useForm } from 'react-hook-form';
import GeositeAutocomplete from 'shared/components/autocomplete/GeositeAutocomplete';
import PersonAutocomplete from 'shared/components/autocomplete/PersonAutocomplete';
import ResponsibleFunctionAutocomplete from 'shared/components/autocomplete/ResponsibleFunctionAutocomplete';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { TeamFormType, TeamIcon } from 'shared/model/def/team.def';
import { Geosite, Person, Team } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  team: Partial<Team>;
  disabled?: boolean;
  onSubmit: (team: TeamFormType) => void;
};

const TeamForm = ({ team, disabled = false, onSubmit }: Props) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const resolver = useTeamResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<TeamFormType>({
    defaultValues: {
      ...team,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const titleI18n = team.id ? 'team.edit' : 'team.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleI18n} icon={<TeamIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="name"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field }) => (
                <TextField
                  label={t('name')}
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="person"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <PersonAutocomplete
                    label={t('team.person')}
                    value={value as Person | null}
                    onChange={onChange}
                    error={!!errors.person}
                    helperText={errors.person?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="responsibleFunction"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <ResponsibleFunctionAutocomplete
                    value={value as Person | null}
                    onChange={onChange}
                    error={!!errors.responsibleFunction}
                    helperText={errors.responsibleFunction?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="geosite"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <GeositeAutocomplete
                    label={t('geosite.geosite')}
                    value={value as Geosite | null}
                    onChange={onChange}
                    error={!!errors.geosite}
                    helperText={errors.geosite?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="adresse"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('adresse')}
                  {...field}
                  error={!!errors.adresse}
                  helperText={errors.adresse?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="adresse_2"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('adresse2')}
                  {...field}
                  error={!!errors.adresse_2}
                  helperText={errors.adresse_2?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="cp"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('cp')}
                  {...field}
                  error={!!errors.cp}
                  helperText={errors.cp?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('city')}
                  {...field}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="phone"
              control={control}
              render={({ field: { ref: fieldRef, value, ...rest } }) => (
                <MuiTelInput
                  {...rest}
                  label={t('phone')}
                  defaultCountry="FR"
                  value={value ?? ''}
                  inputRef={fieldRef}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="fax"
              control={control}
              render={({ field: { ref: fieldRef, value, ...rest } }) => (
                <MuiTelInput
                  {...rest}
                  label={t('fax')}
                  defaultCountry="FR"
                  value={value ?? ''}
                  inputRef={fieldRef}
                  error={!!errors.fax}
                  helperText={errors.fax?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('email')}
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="web_site"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('webSite')}
                  {...field}
                  error={!!errors.web_site}
                  helperText={errors.web_site?.message}
                />
              )}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default TeamForm;
