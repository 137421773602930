import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import isString from 'lodash/isString';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import AbstractDecisionIcon from 'modules/sheet/abstract/AbstractDecisionIcon';
import AbstractTypeIcon from 'modules/sheet/abstract/AbstractTypeIcon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPersonAbstractsQuery } from 'services/endpoints/AbstractEndpoints';
import PageTitle from 'shared/components/PageTitle';
import { useGetDefaultMRTOptions } from 'shared/material-data-table/defaultMRTable';
import { AbstractIcon } from 'shared/model/def/abstract.def';
import { Abstract, AbstractAuthor, Person } from 'shared/model/types';

const columnHelper = createMRTColumnHelper<Abstract>();

type Props = {
  person: Person;
};

const PersonAbstracts = ({ person }: Props) => {
  const { t } = useTranslation();
  const defaultMRTOptions = useGetDefaultMRTOptions<Abstract>();

  const { data, isLoading } = useGetPersonAbstractsQuery({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    fields: ['*', 'authors.*', 'thematique.*'],
    filter: {
      authors: {
        person: {
          _eq: person.id,
        },
      },
    },
  });

  const abstracts = data ?? [];

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor('id', {
        header: 'ID',
        size: 80,
        Cell({ cell }) {
          const value = cell?.getValue();
          return (
            <Button size="small" href={`/sheet/abstract/${value}/main`} variant="text">
              {value}
            </Button>
          );
        },
      }),
      columnHelper.accessor('reference', {
        header: t('abstract.reference'),
        size: 120,
      }),
      columnHelper.accessor('type', {
        header: t('abstract.type'),
        size: 100,
        Cell({ cell }) {
          const value = cell?.getValue();
          if (value) {
            return <AbstractTypeIcon type={value} fontSize="small" />;
          }
          return null;
        },
      }),
      columnHelper.accessor('accepted', {
        header: t('abstract.decision'),
        size: 80,
        Cell({ cell, row }) {
          const value = cell?.getValue();
          return <AbstractDecisionIcon decision={value} fontSize="small" />;
        },
      }),
      columnHelper.accessor('finalType', {
        header: t('abstract.type'),
        size: 100,
        Cell({ cell }) {
          const value = cell?.getValue();
          if (value) {
            return <AbstractTypeIcon type={value} fontSize="small" />;
          }
          return null;
        },
      }),
      columnHelper.accessor('thematique.name', {
        header: t('abstract.thematique'),
        grow: 1,
      }),
      columnHelper.accessor('name', {
        header: t('name'),
        grow: 2,
        Cell({ cell }) {
          const value = cell?.getValue();
          if (isString(value)) {
            return <Box>{value}</Box>;
          }
          return null;
        },
      }),
      columnHelper.accessor('authors', {
        header: t('abstract.speaker'),
        grow: 1,
        Cell({ cell }) {
          const value = cell?.getValue();
          if (Array.isArray(value)) {
            const authors = value as AbstractAuthor[];
            const speaker = authors.find(a => a.speaker);
            if (speaker) {
              return (
                <Button
                  size="small"
                  href={`/sheet/person/${speaker.person}/main`}
                  variant="text"
                  disabled={!speaker.person}
                  sx={{
                    color: !speaker.person
                      ? 'var(--mui-palette-text-primary) !important'
                      : undefined,
                  }}
                >
                  {speaker.firstName} {speaker.lastName}
                </Button>
              );
            }
          }
          return null;
        },
      }),
    ];
    return result;
  }, [t]);

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    renderTopToolbarCustomActions: () => {
      return (
        <Box flexGrow={1}>
          <PageTitle
            text={t('abstract.abstract', { count: abstracts.length })}
            icon={<AbstractIcon fontSize="large" />}
          />
        </Box>
      );
    },
    initialState: {
      ...defaultMRTOptions.initialState,
    },
    columns,
    data: abstracts,
    enableGrouping: true,
    state: {
      showProgressBars: isLoading,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default PersonAbstracts;
