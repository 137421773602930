import AbstractMainTab from 'modules/sheet/abstract/AbstractMainTab';
import AbstractSheet from 'modules/sheet/abstract/AbstractSheet';
import AbstractContentTab from 'modules/sheet/abstract/content/AbstractContentTab';
import AbstractPDFTab from 'modules/sheet/abstract/pdf/AbstractPDFTab';
import { Outlet, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: 'abstract/:id',
    element: (
      <AbstractSheet>
        <Outlet />
      </AbstractSheet>
    ),
    children: [
      {
        path: 'main',
        element: <AbstractMainTab />,
      },
      {
        path: 'pdf',
        element: <AbstractPDFTab />,
      },
      {
        path: 'content',
        element: <AbstractContentTab />,
      },
    ],
  },
];

export default routes;
