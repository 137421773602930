import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import cloneDeep from 'lodash/cloneDeep';
import { setFrom, setIndexFilter, setQuery } from 'shared/reducers/searchSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';
type Props = {
  variant?: 'text' | 'outlined' | 'contained';
  icon?: boolean;
};

const SearchIndexFilterBtn = ({ variant = 'outlined', icon = true }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();
  const indexFilter = useAppSelector(state => state.search.indexFilter);
  const query = useAppSelector(state => state.search.query);

  if (!indexFilter) {
    return null;
  }

  const onIndexFilterClick = () => {
    dispatch(setFrom(0));
    const newQuery = cloneDeep(query);
    const must = newQuery?.bool?.must ?? [];

    if (Array.isArray(must)) {
      const newMust = must.filter(item => {
        return !item.terms;
      });
      dispatch(
        setQuery({
          ...newQuery,
          bool: {
            must: newMust,
          },
        }),
      );
      dispatch(setIndexFilter(''));
    }
  };

  return (
    <Button
      variant={variant}
      color="info"
      onClick={onIndexFilterClick}
      endIcon={icon ? <DeleteIcon /> : undefined}
      size="small"
    >
      {t(`searchFacet.${indexFilter}s`)}
    </Button>
  );
};

export default SearchIndexFilterBtn;
