import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslate } from '@tolgee/react';
import { z } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useResetPwdResolver = () => {
  const { t } = useTranslate();

  const requiredMessage = t('form.errors.required');
  const matchPasswordMessage = t('form.errors.matchPassword');

  const schema = z
    .object({
      email: z.string().min(1, { message: requiredMessage }),
      password: z.string().min(1, { message: requiredMessage }),
      confirmPassword: z.string().min(1, { message: requiredMessage }),
    })
    .superRefine(({ confirmPassword, password }, ctx) => {
      if (confirmPassword !== password) {
        ctx.addIssue({
          path: ['confirmPassword'],
          code: 'custom',
          message: matchPasswordMessage,
        });
      }
    });

  return zodResolver(schema);
};
export default useResetPwdResolver;
