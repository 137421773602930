import { Box, Card, CardContent, Grid2, List, ListItem } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import cloneDeep from 'lodash/cloneDeep';
import SearchFacetBtn from 'modules/search/results/SearchFacetBtn';
import SearchIndexFilterBtn from 'modules/search/results/SearchIndexFilterBtn';
import { useGetSearchFacets, useGetSearchText } from 'modules/search/searchHook';
import Loader from 'shared/components/Loader';
import { setFrom, setIndexFilter, setQuery } from 'shared/reducers/searchSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

type Props = {
  loading: boolean;
};

const SearchNoResult = ({ loading }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();
  const indexFilter = useAppSelector(state => state.search.indexFilter);
  const query = useAppSelector(state => state.search.query);
  const text = useGetSearchText();
  const facets = useGetSearchFacets();

  if (loading) {
    return <Loader />;
  }

  const onIndexFilterClick = () => {
    dispatch(setFrom(0));
    const newQuery = cloneDeep(query);
    const must = newQuery?.bool?.must ?? [];

    if (Array.isArray(must)) {
      const newMust = must.filter(item => {
        return !item.terms;
      });
      dispatch(
        setQuery({
          ...newQuery,
          bool: {
            must: newMust,
          },
        }),
      );
      dispatch(setIndexFilter(''));
    }
  };

  return (
    <Grid2 mt={6} container>
      <Grid2>
        <Card>
          <CardContent>
            <Box>
              {t('searchPage.noResult.title')}
              <Box component="span" fontWeight="bold" marginLeft={1}>
                "{text}"
              </Box>
            </Box>

            {indexFilter && (
              <Box mt={2}>
                <Box>{t('filters')}</Box>
                <Box display="flex">
                  <Box mr={0.4}>
                    <SearchIndexFilterBtn />
                  </Box>

                  {facets.map((f, index) => (
                    <Box key={index} mr={1}>
                      <SearchFacetBtn facet={f} />
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Box mt={2}>
              <Box>{t('searchPage.noResult.Suggests')}</Box>

              <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
                <ListItem sx={{ display: 'list-item' }}>
                  {t('searchPage.noResult.checkSpelling')}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  {t('searchPage.noResult.differentKeywords')}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  {t('searchPage.noResult.generalKeywords')}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  {t('searchPage.noResult.lessWords')}
                </ListItem>
              </List>
            </Box>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default SearchNoResult;
