import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { FieldErrors } from 'react-hook-form';

const showFormError = import.meta.env.VITE_SHOW_FORM_ERROR;

export const showErrors = (errors?: FieldErrors<object>) =>
  showFormError && errors && Object.keys(errors).length > 0;

interface HasId {
  id: number;
  [key: string]: any;
}

// Remove negative id from object (object with id negative is a temporary object)
export const removeNegativeId = (object: HasId) => {
  const id = get(object, 'id');
  if (isNumber(id) && id < 0) {
    const { id, ...rest } = object;
    return rest;
  }
  return object;
};
