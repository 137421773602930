import { Text } from '@react-pdf/renderer';

import Html, { HtmlStyles } from 'react-pdf-html';
import { Abstract } from 'shared/model/types';

type Props = {
  abstract: Abstract;
  html?: boolean;
};

const AbstractPdfContent = ({ abstract, html = false }: Props) => {
  if (!abstract.content) {
    return null;
  }

  const content = abstract.content;
  //content = content.replace(/<\/sup>/g, '&nbsp;</sup>');

  if (html) {
    const stylesheet: HtmlStyles = {
      p: {
        fontSize: 12,
        marginBottom: 10,
        //textAlign: 'justify',
      },
      div: {
        fontSize: 12,
        lineHeight: 1.5,
      },
      sup: {
        verticalAlign: 'super',
        fontSize: 8,
      },
      sub: {
        verticalAlign: 'sub',
        fontSize: 8,
      },
    };

    const html = `<div>${content}</div>`;

    return (
      <Html stylesheet={stylesheet} resetStyles>
        {html}
      </Html>
    );
  }

  return <Text>{content}</Text>;
};

export default AbstractPdfContent;
