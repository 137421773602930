import { DevTools, FormatSimple, Tolgee } from '@tolgee/react';

const apiUrl = import.meta.env.VITE_APP_TOLGEE_API_URL;
const apiKey = import.meta.env.VITE_APP_TOLGEE_API_KEY;

const tolgee = Tolgee().use(DevTools()).use(FormatSimple()).init({
  language: 'fr-FR',
  apiUrl,
  apiKey,
});
export default tolgee;
