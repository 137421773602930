import { useDeletePublicationMutation } from 'services/endpoints/PublicationEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';
import { Publication } from 'shared/model/types';

type Props = {
  items: Publication[];
};

const DeletePublicationsBtn = ({ items }: Props) => {
  const [deletePubs] = useDeletePublicationMutation();

  const onConfirm = async () => {
    deletePubs(items);
  };

  return <ConfirmDelete onConfirm={onConfirm} noText />;
};

export default DeletePublicationsBtn;
