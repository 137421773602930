import { Box } from '@mui/material';
import DeletePersonsBtn from 'modules/sheet/person/DeletePersonsBtn';
import { useGetPerson } from 'modules/sheet/sheetHook';
import { useNavigate } from 'react-router-dom';
import IconValue from 'shared/components/IconValue';
import Loader from 'shared/components/Loader';
import SheetContent from 'shared/components/sheet/SheetContent';
import SheetTitle from 'shared/components/sheet/SheetTitle';
import { useSaveHistory } from 'shared/hooks/historyHook';
import ModelName from 'shared/model/def/ModelName';
import { PersonIcon } from 'shared/model/def/person.def';

const tabs = [
  {
    label: 'sheetPerson.tabMain',
    path: 'main',
  },
  {
    label: 'sheetPerson.tabFollow',
    path: 'follow',
  },
  {
    label: 'sheetPerson.tabParticipations',
    path: 'participations',
  },
];

type Props = {
  children: React.ReactNode;
};

const PersonSheet = ({ children }: Props) => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetPerson();

  useSaveHistory('person', data?.id, data ? data.firstName + ' ' + data.lastName : undefined);

  if (isLoading) {
    return <Loader />;
  }
  if (!data) {
    return null;
  }

  const onDeleted = () => {
    navigate('/search');
  };

  return (
    <Box px={1}>
      <SheetTitle actions={<DeletePersonsBtn ids={[data.id]} onDeleted={onDeleted} />}>
        <IconValue icon={<PersonIcon fontSize="large" />}>
          <ModelName model={data} type="person" fontSize={24} />
        </IconValue>
      </SheetTitle>
      <SheetContent tabs={tabs} matchPath="/sheet/person/:id/:tab">
        {children}
      </SheetContent>
    </Box>
  );
};

export default PersonSheet;
