import { Text } from '@react-pdf/renderer';
import { AuthorRank } from 'modules/sheet/abstract/AbstractHook';

type Props = {
  item: AuthorRank;
  last?: boolean;
  showRank?: boolean;
};

const AuthorPdf = ({ item, last = false, showRank }: Props) => {
  const author = item.aa;
  const rank = item.ranks;
  return (
    <Text
      style={{
        fontWeight: author.speaker ? 'bold' : undefined,
      }}
    >
      <Text>
        {author.firstName} {author.lastName}
      </Text>
      {showRank && (
        <Text
          style={{
            verticalAlign: 'super',
            fontSize: 8,
          }}
        >
          {rank.map(r => r.rank).join(',')}
        </Text>
      )}
      {!last && <Text>, </Text>}
    </Text>
  );
};

export default AuthorPdf;
