import { Box, Grid2 as Grid } from '@mui/material';
import AbstractInformations from 'modules/sheet/abstract/AbstractInformations';
import AbstractAuthors from 'modules/sheet/abstract/authors/AbstractAuthors';

import DisplayAbstract from 'modules/sheet/abstract/DisplayAbstract';
import EditAbstractBtn from 'modules/sheet/abstract/EditAbstractBtn';
import { useGetAbstract } from 'modules/sheet/sheetHook';
import { useTranslation } from 'react-i18next';
import EditableCard from 'shared/components/Card/EditableCard';

const AbstractMainTab = () => {
  const { t } = useTranslation();
  const { data } = useGetAbstract();

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 6,
            xl: 4,
          }}
        >
          <Box mb={2}>
            <AbstractInformations abstract={data} />
          </Box>
          <EditableCard
            title={t('abstract.authors')}
            actions={<EditAbstractBtn hideInformations abstract={data} />}
          >
            <AbstractAuthors
              abstract={data}
              showIcon
              showStructure
              showLink
              showLinkToPerson
              showCivilite
            />
          </EditableCard>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
            xl: 8,
          }}
        >
          <Box>
            <DisplayAbstract abstract={data} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AbstractMainTab;
