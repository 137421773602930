import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import AbstractDecisionIcon from 'modules/sheet/abstract/AbstractDecisionIcon';
import AbstractTypeIcon from 'modules/sheet/abstract/AbstractTypeIcon';
import { useTranslation } from 'react-i18next';
import { Abstract } from 'shared/model/types';

type Props = {
  abstract: Abstract;
  elevation?: number;
};

const AbstractTypesTable = ({ abstract, elevation = 4 }: Props) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={elevation}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('abstract.type')}</TableCell>
            <TableCell align="center">{t('abstract.decision')}</TableCell>
            <TableCell align="center">{t('abstract.finalType')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">
              <AbstractTypeIcon type={abstract.type} fontSize="small" />
            </TableCell>
            <TableCell align="center">
              <AbstractDecisionIcon decision={abstract.accepted} fontSize="small" />
            </TableCell>
            <TableCell align="center">
              <AbstractTypeIcon type={abstract.finalType} fontSize="small" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AbstractTypesTable;
