import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import LinkAuthorToPersonForm from 'modules/sheet/abstract/authors/LinkAuthorToPersonForm';
import { useTranslation } from 'react-i18next';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { AbstractAuthor } from 'shared/model/types';
type Props = {
  author: AbstractAuthor;
};

const LinkAuthorToPersonBtn = ({ author }: Props) => {
  const { t } = useTranslation();

  return (
    <OpenDrawerButton
      title={t('associate')}
      icon={<CloseFullscreenIcon color="success" />}
      size="small"
      paperSx={{
        width: '100%',
        maxWidth: '40vw',
      }}
    >
      <LinkAuthorToPersonForm author={author} />
    </OpenDrawerButton>
  );
};

export default LinkAuthorToPersonBtn;
