import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Abstract, AbstractAuthor } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['abstract', 'abstracts', 'personAbstracts'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getAbstracts: builder.query<Abstract[], RequestQuery<Abstract>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Abstract[]>(readItems('Abstract', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['abstracts'],
      }),
      getPersonAbstracts: builder.query<Abstract[], RequestQuery<Abstract>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Abstract[]>(readItems('Abstract', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['abstracts'],
      }),
      getAbstract: builder.query<Abstract, { id: string; q?: RequestQuery<Abstract> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Abstract>(readItem('Abstract', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['abstract'],
      }),
      createAbstract: builder.mutation<
        Abstract,
        {
          item: Partial<Abstract>;
          query?: MutationQuery<Abstract>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Abstract>(createItem('Abstract', q.item, q.query));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['abstract', 'abstracts'],
      }),
      updateAbstract: builder.mutation<
        Abstract,
        {
          id: string | number;
          item: Partial<Abstract>;
          query?: MutationQuery<Abstract>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Abstract>(
              updateItem('Abstract', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['abstract', 'abstracts'],
      }),
      updateAbstractAuthor: builder.mutation<
        AbstractAuthor,
        {
          id: string | number;
          item: Partial<AbstractAuthor>;
          query?: MutationQuery<AbstractAuthor>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<AbstractAuthor>(
              updateItem('AbstractAuthor', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['abstract', 'abstracts'],
      }),
      deleteAbstract: builder.mutation<void, number[]>({
        queryFn: async ids => {
          try {
            await directus.request(deleteItems('Abstract', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['abstract', 'abstracts'],
      }),
    }),
  });

export const {
  useGetAbstractsQuery,
  useGetAbstractQuery,
  useGetPersonAbstractsQuery,
  useCreateAbstractMutation,
  useUpdateAbstractMutation,
  useUpdateAbstractAuthorMutation,
  useDeleteAbstractMutation,
} = extendedApi;
