import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import PageTitle from 'shared/components/PageTitle';
import EscapeTextEditor from 'shared/components/textEditor/EscapeTextEditor';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  title: string;
  content?: string | null;
  onValid?: (content: string) => Promise<any>;
  disabled?: boolean;
};

const DrawerTextEditor = ({ title, content, onValid, disabled = false }: Props) => {
  const dispatch = useAppDispatch();

  const [newContent, setnewContent] = useState(content ? content.toString() : '');

  const onContentChange = (content: string) => {
    setnewContent(content);
  };

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const onConfirm = async () => {
    if (onValid) {
      await onValid(newContent ?? '');
      dispatch(closeAllDrawers());
    }
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <PageTitle text={title} />
        <Box>
          <Button size="small" onClick={onConfirm} color="success">
            <CheckIcon />
          </Button>
          <Button size="small" onClick={onCancel} variant="outlined" sx={{ ml: 1 }}>
            <BlockIcon />
          </Button>
        </Box>
      </Box>
      <EscapeTextEditor
        content={content}
        onChange={onContentChange}
        disabled={disabled}
        autofocus
      />
    </Box>
  );
};

export default DrawerTextEditor;
