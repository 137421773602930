import Box from '@mui/material/Box';
import PersonJobStepper from 'modules/sheet/person/follow/PersonJobStepper';
import { useGetPerson } from 'modules/sheet/sheetHook';

const PersonFollowTab = () => {
  const { data } = useGetPerson();

  if (!data) {
    return null;
  }

  return (
    <Box>
      <PersonJobStepper person={data} />
    </Box>
  );
};

export default PersonFollowTab;
