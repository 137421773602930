import { QueryDslQueryContainer } from '@elastic/elasticsearch/lib/api/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import { setFrom, setQuery } from 'shared/reducers/searchSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

type Props = {
  facet: QueryDslQueryContainer;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: boolean;
};

const SearchFacetBtn = ({ facet, variant = 'outlined', icon = true }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();

  const query = useAppSelector(state => state.search.query);
  const indexFilter = useAppSelector(state => state.search.indexFilter);
  const terms = facet.terms ?? {};
  const termKey = Object.keys(terms)[0];

  //const i18nKey = `searchFacet.${indexFilter}.${Object.keys(terms)[0]}`;

  const onClick = () => {
    dispatch(setFrom(0));
    const newQuery = cloneDeep(query);
    const must = newQuery?.bool?.must ?? [];

    if (Array.isArray(must)) {
      const newMust = must.filter(item => {
        return !Object.keys(item.terms ?? {}).some(aKey => aKey === termKey);
      });
      dispatch(
        setQuery({
          ...newQuery,
          bool: {
            must: newMust,
          },
        }),
      );
    }
  };

  console.log(termKey);

  const value = get(terms, termKey, '');

  let strValue = '';
  if (Array.isArray(value)) {
    strValue = value
      .map(v => {
        if (isBoolean(v)) {
          const str = t(`searchFacet.${indexFilter}.${termKey}`);
          if (v === true) {
            return str;
          } else {
            return t('no') + ' ' + str;
          }
        } else {
          return v;
        }

        return v === true ? t('yes') : v === false ? t('no') : v;
      })
      .join(', ');
  }

  return (
    <Button
      variant={variant}
      color="info"
      onClick={onClick}
      endIcon={icon ? <DeleteIcon /> : undefined}
      size="small"
      sx={{
        minWidth: '40px',
        whiteSpace: 'nowrap',
      }}
    >
      {strValue}
    </Button>
  );
};

export default SearchFacetBtn;
