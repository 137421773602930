import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import isObject from 'lodash/isObject';
import { TeamMemberFormType } from 'modules/sheet/team/teamMember/teamMember.def';
import TeamMemberForm from 'modules/sheet/team/teamMember/TeamMemberForm';
import { useCreateTeamMemberMutation } from 'services/endpoints/TeamMemberEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  teamId: number;
};

const AddTeamMember = ({ teamId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();

  const newTeamMember: TeamMemberFormType = {};

  const [create, { isLoading }] = useCreateTeamMemberMutation();

  const onSubmit = async (teamMember: TeamMemberFormType) => {
    if (isObject(teamMember.person) && isObject(teamMember.metier) && isObject(teamMember.statut)) {
      await create({
        item: {
          label: teamMember.label,
          person: teamMember.person.id,
          metier: teamMember.metier.id,
          statut: teamMember.statut.id,
          startDate: teamMember.startDate,
          endDate: teamMember.endDate,
          team: teamId,
        },
      }).unwrap();
      dispatch(closeAllDrawers());
    }
  };

  const disabled = isLoading;

  return (
    <OpenDrawerButton type="button" icon={<AddIcon />}>
      <Box p={2}>
        <TeamMemberForm teamMember={newTeamMember} onSubmit={onSubmit} disabled={disabled} />
      </Box>
    </OpenDrawerButton>
  );
};

export default AddTeamMember;
