import isBoolean from 'lodash/isBoolean';
import { AbstractAcceptedIcon, AbstractNotAcceptedIcon } from 'shared/model/def/abstract.def';

type Props = {
  decision?: boolean | null | undefined;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
};

const AbstractDecisionIcon = ({ decision, fontSize }: Props) => {
  if (isBoolean(decision)) {
    if (decision) {
      return <AbstractAcceptedIcon color="success" fontSize={fontSize} />;
    }
    return <AbstractNotAcceptedIcon color="error" fontSize={fontSize} />;
  }
  return null;
};

export default AbstractDecisionIcon;
