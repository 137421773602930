import { Box } from '@mui/material';
import DOMPurify from 'dompurify';

import { RichTextEditorProvider, RichTextField } from 'mui-tiptap';
import EditorMenuControls from 'shared/components/textEditor/EditorMenuControls';
import './EscapeTextEditor.scss';

import { EditorEvents, useEditor } from '@tiptap/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useEditorExtensions } from './useEditorExtensions';

type Props = {
  content?: string | null;
  preview?: boolean;
  onChange?: (content: string) => void;
  disabled?: boolean;
  locale?: 'en' | 'fr';
  limit?: number;
  autofocus?: boolean;
  className?: string;
};

const EscapeTextEditor = ({
  content,
  preview,
  onChange,
  disabled = false,
  autofocus = false,
  locale = 'fr',
  limit = 3500,
  className,
}: Props) => {
  const { t } = useTranslation();

  const extensions = useEditorExtensions({
    placeholder: t('editor.placeholder'),
    characterLimit: limit,
  });

  const onUpdate = ({ editor }: EditorEvents['update']) => {
    if (onChange) {
      onChange(editor.getHTML());
    }
  };

  const editor = useEditor({
    autofocus,
    extensions,
    content,
    onUpdate,
  });

  if (preview) {
    return (
      <Box
        className="escape-text-preview"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content ?? ''),
        }}
      ></Box>
    );
  }

  const nbChar = editor?.storage.characterCount.characters();

  return (
    <Box>
      <RichTextEditorProvider editor={editor}>
        <RichTextField
          disabled={disabled}
          className={clsx('escape-text-editor', className)}
          controls={<EditorMenuControls />}
        />
      </RichTextEditorProvider>
      <Box display="flex" justifyContent="space-between" fontSize="smaller" mt={1}>
        <Box>{t('editor.limit', { nb: limit })}</Box>
        <Box>{t('editor.currentCharCount', { nb: nbChar })}</Box>
      </Box>
    </Box>
  );
};

export default EscapeTextEditor;
