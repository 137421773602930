import { Font } from '@react-pdf/renderer';

import RobotoBold from 'assets/fonts/roboto/Roboto-Bold.ttf';
import RobotoBoldItalic from 'assets/fonts/roboto/Roboto-BoldItalic.ttf';
import RobotoItalic from 'assets/fonts/roboto/Roboto-Italic.ttf';
import Roboto from 'assets/fonts/roboto/Roboto-Regular.ttf';

export const registerPDFFonts = () => {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: Roboto,
      },
      {
        src: RobotoBold,
        fontWeight: 'bold',
      },
      {
        src: RobotoItalic,
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
      {
        src: RobotoBoldItalic,
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
    ],
    format: 'truetype',
  });
};
