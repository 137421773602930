import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PersonIcon from '@mui/icons-material/Person';
import { Abstract, AbstractAuthor } from 'shared/model/types';

export const AbstractIcon = MenuBookIcon;

export type AbstractFormType = Omit<Abstract, 'id'>;

export type AbstractAuthorFormType = Omit<AbstractAuthor, 'id'>;

export const abstractTypes = ['O', 'OP', 'P', 'I', 'A'] as const;

export type AbstractType = (typeof abstractTypes)[number];

export const AbstractOralIcon = ChatBubbleIcon;
export const AbstractPosterIcon = PanoramaIcon;
export const AbstractInvitationIcon = PersonIcon;
export const AbstractCancelledIcon = ClearIcon;

export const AbstractAcceptedIcon = CheckIcon;
export const AbstractNotAcceptedIcon = CloseIcon;
