import Button from '@mui/material/Button';

import { useTranslate } from '@tolgee/react';

import { useMatch, useNavigate } from 'react-router-dom';

const LoginHeaderBtn = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const match = useMatch('/login');

  const onClick = () => {
    navigate('/login');
  };

  return (
    <Button variant="text" sx={{ color: match ? '#FFF' : '#aaa' }} onClick={onClick}>
      {t('login')}
    </Button>
  );
};

export default LoginHeaderBtn;
