import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import PublicationForm from 'modules/sheet/structure/publications/PublicationForm';
import { useTranslation } from 'react-i18next';
import { useCreatePublicationMutation } from 'services/endpoints/PublicationEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { PublicationFormType } from 'shared/model/def/Publication.def';
import { Publication } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structureId: number;
};

const CreateStructurePublicationBtn = ({ structureId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [createPublication, { isLoading }] = useCreatePublicationMutation();

  const onSubmit = async (newPub: PublicationFormType) => {
    const newPublication: Omit<Publication, 'id'> = {
      ...newPub,
      structure: structureId,
    };
    await createPublication({
      item: newPublication,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton type="button" text={t('create')} icon={<AddIcon />}>
      <PublicationForm
        publication={{
          title: '',
          content: '',
          web_site: '',
          pub_date: dayjs(),
        }}
        onSubmit={onSubmit}
        disabled={isLoading}
      />
    </OpenDrawerButton>
  );
};

export default CreateStructurePublicationBtn;
