import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGisMap from '@arcgis/core/Map';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import MapView from '@arcgis/core/views/MapView';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import LocationSearch from 'shared/components/map/LocationSearch';

const defaultPosition = import.meta.env.VITE_DEFAULT_POSITION;

const defaultLatitude = defaultPosition.split(',')[0];
const defaultLongitude = defaultPosition.split(',')[1];

const symbol = new SimpleMarkerSymbol({
  color: [60, 100, 170],
  size: '24px',
  style: 'diamond',
  outline: {
    color: [255, 255, 255],
    width: 1,
  },
});

type Props = {
  defaultAddress?: string;
  point?: Point | null;
  className?: string;
  zoom?: number;
  onSave: (point: Point) => void;
  onCancel: () => void;
};

const EditGeomInMap = ({
  point,
  defaultAddress,
  className,
  zoom = 12,
  onSave,
  onCancel,
}: Props) => {
  const { t } = useTranslate();
  const mapRef = useRef<HTMLDivElement>(null);
  const vmLayer = useRef<GraphicsLayer>(null);
  const viewRef = useRef<MapView>(null);

  useEffect(() => {
    if (mapRef?.current) {
      const center = point
        ? [point.latitude, point.longitude]
        : [defaultLatitude, defaultLongitude];
      const layer = new GraphicsLayer();
      vmLayer.current = layer;

      const map = new ArcGisMap({
        basemap: 'osm',
        ground: 'world-elevation',
        layers: [layer],
      });

      const view = new MapView({
        map,
        container: mapRef.current,
        center,
        zoom,
        spatialReference: SpatialReference.WebMercator,
        constraints: {
          minZoom: 3,
        },
        ui: {
          components: [],
        },
      });
      viewRef.current = view;

      const vm = new SketchViewModel({
        view,
        layer,
        pointSymbol: symbol,
        defaultCreateOptions: {
          hasZ: false,
        },
        defaultUpdateOptions: {
          enableZ: false,
        },
      });

      if (point) {
        view.when(() => {
          const g = new Graphic({
            geometry: point,
            symbol,
          });
          layer.add(g);
          vm.update(g);
          view.goTo({
            target: point,
            zoom,
          });
        });
      } else {
        vm.create('point');
      }
    }
  }, [mapRef]);

  const onSaveClick = () => {
    const graphics = vmLayer.current?.graphics;
    if (graphics && graphics.length > 0) {
      const g = graphics.getItemAt(0);
      onSave(g.geometry as Point);
    }
  };

  const onSearchComplete = (newPoint: Point) => {
    vmLayer.current?.removeAll();
    const g = new Graphic({
      geometry: newPoint,
      symbol,
    });
    vmLayer.current?.add(g);

    viewRef.current?.goTo(
      {
        target: newPoint,
      },
      {
        duration: 0,
      },
    );
  };

  return (
    <Box p={1}>
      <Card>
        <CardHeader
          title={t('editGeom')}
          subheader={t('editGeomDescription')}
          sx={{
            paddingBottom: 0,
          }}
        />
        <CardContent>
          <Box mb={1}>
            <LocationSearch defaultAddress={defaultAddress} onSelection={onSearchComplete} />
          </Box>
          <Box className={clsx('escape-map', className)} ref={mapRef}></Box>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button onClick={onCancel} startIcon={<CancelIcon />} variant="outlined">
            {t('cancel')}
          </Button>
          <Button onClick={onSaveClick} color="primary" startIcon={<SaveIcon />}>
            {t('save')}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default EditGeomInMap;
