import { DirectusUser } from '@directus/sdk';
import { Box, Paper } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import isObject from 'lodash/isObject';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import CreateStructurePublicationBtn from 'modules/sheet/structure/publications/CreateStructurePublicationBtn';
import DeletePublicationsBtn from 'modules/sheet/structure/publications/DeletePublicationsBtn';
import DisplayPublication from 'modules/sheet/structure/publications/DisplayPublication';
import EditStructurePublicationBtn from 'modules/sheet/structure/publications/EditPublicationBtn';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPublicationsQuery } from 'services/endpoints/PublicationEndpoints';
import CustomSelectFilter from 'shared/material-data-table/CustomSelectFilter';
import { useGetDefaultMRTOptions } from 'shared/material-data-table/defaultMRTable';
import { Publication, PublicationType } from 'shared/model/types';
import { LabelValue, sortByLabel } from 'shared/utils/array-utils';

import { DATE_FORMAT } from 'shared/utils/date-utils';

const columnHelper = createMRTColumnHelper<Publication>();

const StructurePublicationsTab = () => {
  const { t } = useTranslate();
  const defaultMRTOptions = useGetDefaultMRTOptions<Publication>();

  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGetPublicationsQuery({
    filter: {
      structure: Number(id),
    },

    fields: [
      'id',
      'pub_date',
      'title',
      'web_site',
      'content',
      // @ts-ignore
      'user_created.id',
      // @ts-ignore
      'user_created.first_name',
      // @ts-ignore
      'user_created.last_name',
      // @ts-ignore
      'user_updated.id',
      // @ts-ignore
      'user_updated.first_name',
      // @ts-ignore
      'user_updated.last_name',
      // @ts-ignore
      'type.*',
    ],
    sort: ['-pub_date'],
  });

  const publications = data ?? [];

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor(row => dayjs(row.pub_date), {
        id: 'pub_date',
        header: t('publication.pubDate'),
        grow: 1,
        filterVariant: 'date-range',
        filterFn: 'lessThan',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (value.isValid()) {
            return dayjs(value).format(DATE_FORMAT);
          }
          return '';
        },
      }),
      columnHelper.accessor('type.name', {
        header: t('type'),
        grow: 1,
        filterVariant: 'select',
        Filter: ({ header }) => {
          const items = publications.reduce((acc: LabelValue<string>[], pub) => {
            if (
              isObject(pub.type) &&
              !acc.some(p => p.value === ((pub?.type as PublicationType)?.name ?? 0))
            ) {
              acc.push({
                label: pub.type.name ?? '',
                value: pub.type.name ?? '',
              });
            }

            return acc;
          }, []);

          items.sort(sortByLabel());

          return <CustomSelectFilter header={header} items={items} />;
        },
      }),
      columnHelper.accessor('title', {
        header: t('title'),
        grow: 6,
      }),
      columnHelper.accessor('web_site', {
        header: t('webSite'),
        grow: 6,
        Cell({ cell }) {
          const webSite = cell.getValue();
          if (webSite) {
            return (
              <a href={webSite} target="_blank">
                {webSite}
              </a>
            );
          }
          return null;
        },
      }),
      columnHelper.accessor('user_created', {
        header: t('user_created'),
        grow: 1,
        Cell({ cell }) {
          const user = cell.getValue() as DirectusUser;
          if (user) {
            return `${user.first_name} ${user.last_name}`;
          }
          return null;
        },
      }),
      columnHelper.accessor('user_updated', {
        header: t('user_updated'),
        grow: 1,
        Cell({ cell }) {
          const user = cell.getValue() as DirectusUser;
          if (user) {
            return `${user.first_name} ${user.last_name}`;
          }
          return null;
        },
      }),
    ];
    return result;
  }, [t, publications]);

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    renderTopToolbarCustomActions: ({ table }) => {
      const currentSelection = table.getSelectedRowModel().flatRows.map(row => row.original);
      return (
        <>
          <Box flexGrow={1}>
            {currentSelection.length === 0 ? (
              <>
                <CreateStructurePublicationBtn structureId={Number(id)} />
              </>
            ) : (
              <>
                {currentSelection.length === 1 && (
                  <EditStructurePublicationBtn publication={currentSelection[0]} />
                )}
                <DeletePublicationsBtn items={currentSelection} />
              </>
            )}
          </Box>
        </>
      );
    },
    state: {
      isLoading,
      showProgressBars: isLoading,
    },
    initialState: {
      ...defaultMRTOptions.initialState,
    },
    columns,
    data: publications,
  });

  const selection = table.getSelectedRowModel().flatRows.map(row => row.original);

  return (
    <>
      <Paper elevation={3}>
        <Box p={1}>
          <MaterialReactTable table={table} />
        </Box>
        {selection.length > 0 && (
          <Box p={1}>
            {selection.map(p => (
              <DisplayPublication publication={p} key={p.id} />
            ))}
          </Box>
        )}
      </Paper>
    </>
  );
};

export default StructurePublicationsTab;
