import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslate } from '@tolgee/react';
import { Dayjs } from 'dayjs';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { PublicationFormType } from 'shared/model/def/Publication.def';
import { PublicationType } from 'shared/model/types';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const usePublicationResolver = () => {
  const { t } = useTranslate();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    type: z.custom<PublicationType>(value => isObject(value), { message: requiredMessage }),
    title: z.string().min(1, requiredMessage),
    pub_date: z.custom<Dayjs>(value => isObject(value) || isString(value), {
      message: requiredMessage,
    }),
    content: z.string(),
    web_site: z.string(),
  }) satisfies ZodType<PublicationFormType>;

  return zodResolver(schema);
};
export default usePublicationResolver;
