import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { useGetTeamsQuery } from 'services/endpoints/TeamEndpoints';
import IconValue from 'shared/components/IconValue';
import { useGetDefaultMRTOptions } from 'shared/material-data-table/defaultMRTable';
import { TeamIcon } from 'shared/model/def/team.def';
import { Team } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const columnHelper = createMRTColumnHelper<Team>();

type Props = {
  selected: number[];
  onChange: (teams: Team[]) => void;
  tableSx?: SxProps<Theme>;
};

const TeamsSelection = ({ selected, tableSx, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();
  const defaultMRTOptions = useGetDefaultMRTOptions<Team>();

  const { data, isLoading } = useGetTeamsQuery({
    fields: [
      'id',
      'name',
      //@ts-ignore
      'structure.id',
      //@ts-ignore
      'structure.name',
    ],
    sort: 'name',
    limit: 500,
  });

  const teams = data ?? [];

  const defaultRowSelection = useMemo(() => {
    return selected.reduce((acc: any, id) => {
      acc[id] = true;
      return acc;
    }, {});
  }, [selected]);

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor('name', {
        header: t('name'),
        grow: 2,
      }),
      columnHelper.accessor('structure.name', {
        header: t('structure.structure'),
        grow: 1,
      }),
    ];
    return result;
  }, [t]);

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    initialState: {
      ...defaultMRTOptions.initialState,
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      showColumnFilters: true,
      rowSelection: defaultRowSelection,
    },
    enableTopToolbar: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    positionToolbarAlertBanner: 'top',
    columns,
    data: teams,
    state: {
      isLoading,
    },
    muiTableContainerProps: {
      sx: tableSx,
    },
  });

  const onConfirm = async () => {
    const ids = Object.keys(table.getState().rowSelection).map(id => parseInt(id));
    const selectedTeams = teams.filter(t => ids.includes(t.id));
    onChange(selectedTeams);
  };

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <IconValue icon={<TeamIcon />}>
          <Box>{t('selectItem')}</Box>
        </IconValue>
        <Box>
          <Button size="small" onClick={onConfirm} color="success">
            <CheckIcon />
          </Button>
          <Button size="small" onClick={onCancel} variant="outlined" sx={{ ml: 1 }}>
            <BlockIcon />
          </Button>
        </Box>
      </Box>
      <Box>
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};

export default TeamsSelection;
