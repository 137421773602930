import InfoIcon from '@mui/icons-material/Info';
import { t } from 'i18next';
import SearchResultPerson from 'modules/search/results/SearchResultPerson';
import EditPersonBtn from 'modules/sheet/person/EditPersonBtn';
import EditableCard from 'shared/components/Card/EditableCard';
import IconValue from 'shared/components/IconValue';
import { Person } from 'shared/model/types';

type Props = {
  person: Person;
};

const PersonInfo = ({ person }: Props) => {
  return (
    <EditableCard
      title={<IconValue icon={<InfoIcon />}>{t('contact')}</IconValue>}
      actions={<EditPersonBtn item={person} />}
    >
      <SearchResultPerson person={person} noDescription noScientificAttachments />
    </EditableCard>
  );
};

export default PersonInfo;
