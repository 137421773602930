import AbstractForm from 'modules/sheet/abstract/form/AbstractForm';
import { useUpdateAbstractMutation } from 'services/endpoints/AbstractEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { removeNegativeId } from 'shared/components/form/form-utils';
import { AbstractFormType } from 'shared/model/def/abstract.def';
import { Abstract, AbstractAuthor } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  abstract: Abstract;
  hideInformations?: boolean;
  hideAuthors?: boolean;
};

const EditAbstractBtn = ({ abstract, hideInformations = false, hideAuthors = false }: Props) => {
  const dispatch = useAppDispatch();

  const [updateItem, { isLoading }] = useUpdateAbstractMutation();

  const onSubmit = async (newAbstract: AbstractFormType) => {
    const authors = newAbstract.authors as AbstractAuthor[];
    const newAutthors = authors.map(author => removeNegativeId(author));

    const item = {
      ...newAbstract,
      authors: newAutthors,
    };

    await updateItem({
      id: abstract.id,
      item,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton
      paperSx={{
        width: '100%',
        maxWidth: '40vw',
      }}
    >
      <AbstractForm
        hideInformations={hideInformations}
        hideAuthors={hideAuthors}
        abstract={{ ...abstract }}
        onSubmit={onSubmit}
        disabled={isLoading}
      />
    </OpenDrawerButton>
  );
};

export default EditAbstractBtn;
