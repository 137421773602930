import { Box, Button, Grid2 as Grid } from '@mui/material';
import { useGetAbstract } from 'modules/sheet/sheetHook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateAbstractMutation } from 'services/endpoints/AbstractEndpoints';

import EscapeTextEditor from 'shared/components/textEditor/EscapeTextEditor';
import { addCatchErrorAlert, addSuccessAlert } from 'shared/reducers/alertSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const AbstractContentTab = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data, isLoading } = useGetAbstract();

  const [updateItem, { isLoading: isUpdating }] = useUpdateAbstractMutation();
  const [content, setContent] = useState(data?.content);

  useEffect(() => {
    if (data?.content) {
      setContent(data.content);
    }
  }, [data?.content]);

  if (!data) {
    return null;
  }

  const onChange = (newContent: string) => {
    setContent(newContent);
  };

  const onSave = async () => {
    try {
      await updateItem({
        id: data.id,
        item: {
          content,
        },
      }).unwrap();
      dispatch(addSuccessAlert(t('abstract.contentSaved')));
    } catch (e) {
      dispatch(addCatchErrorAlert(e));
    }
  };

  return (
    <Grid container spacing={2} justifyContent="left">
      <Grid size={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 6 }}>
        <Box mb={2} display="flex" justifyContent="space-between">
          <Box fontSize="small" mr={2}>
            {t('abstract.contentHelper')}
          </Box>
          <Box display="flex" alignItems="start">
            <Button onClick={onSave} disabled={isUpdating || isLoading}>
              {t('save')}
            </Button>
          </Box>
        </Box>
        <EscapeTextEditor
          className="abstract-content-editor"
          content={data?.content}
          onChange={onChange}
          disabled={isUpdating}
          autofocus
        />
      </Grid>
    </Grid>
  );
};

export default AbstractContentTab;
