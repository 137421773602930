import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslate } from '@tolgee/react';
import { z } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useLoginResolver = () => {
  const { t } = useTranslate();

  const emailMessage = t('form.errors.emailPattern');
  const passwordMessage = t('form.errors.required') as string;

  const credentialsSchema = z.object({
    email: z.string().email({ message: emailMessage }),
    password: z.string().min(1, { message: passwordMessage }),
  });

  return zodResolver(credentialsSchema);
};

export default useLoginResolver;
