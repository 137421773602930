import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { jwtDecode } from 'jwt-decode';
import { ConfirmInviteForm, Token } from 'modules/admin/users/InviteUser.model';
import useConfirmInviteResolver from 'modules/user/confirmInvite/useConfirmInviteResolver';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useConfirmInviteMutation } from 'services/endpoints/UsersEndpoints';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import PasswordI18nStrength from 'shared/components/form/PasswordI18nStrength';
import PasswordTextField from 'shared/components/form/PasswordTextField';
import { useDirectusAppLogoUrl } from 'shared/hooks/directus-hook';
import { addCatchErrorAlert } from 'shared/reducers/alertSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
import { validatePassword } from 'shared/utils/password-utils';

const ConfirmInvite = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoUrl = useDirectusAppLogoUrl();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [confirmInvite, { isLoading }] = useConfirmInviteMutation();

  const token = searchParams.get('token');
  const info = token ? jwtDecode<Token>(token) : undefined;

  const resolver = useConfirmInviteResolver();

  const endUrl = '/';

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ConfirmInviteForm>({
    defaultValues: {
      email: info?.email,
      password: '',
    },
    resolver,
  });

  if (!token || info?.scope !== 'invite') {
    return <Navigate to={endUrl} />;
  }

  const onSubmit = async (data: ConfirmInviteForm) => {
    try {
      await confirmInvite({
        token,
        password: data.password,
      }).unwrap();

      navigate(endUrl);
    } catch (e) {
      dispatch(addCatchErrorAlert(e));
    }
  };

  const currentPwd = watch('password');

  const validPwd = validatePassword(currentPwd, t).length === 0;

  const isDisabled = isLoading || validPwd;

  return (
    <Box mt={4}>
      <FormTemplate
        titlei18n="user.confirmInvite"
        icon={<img alt="logo" src={`${logoUrl}?key=logo-big`} />}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('email')}
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  disabled
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordTextField
                  label={t('password')}
                  {...field}
                  type="password"
                  autoComplete="new-password"
                  disabled={isLoading}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  autoFocus
                />
              )}
            />
          </Box>
          <PasswordI18nStrength password={currentPwd} />
          <FormButtons cancelUrl={endUrl} disabled={isDisabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default ConfirmInvite;
