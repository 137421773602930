import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { CiviliteType } from 'shared/model/def/person.def';
type Props = {
  value: CiviliteType;
  onChange: (newValue: CiviliteType) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const CiviliteSelect = ({ value, onChange }: Props) => {
  const { t } = useTranslate();

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as CiviliteType);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="civilite">{t('civilite')}</InputLabel>
      <Select
        labelId="civilite"
        id="civilite-select"
        value={value as string}
        label={t('civilite')}
        onChange={handleChange}
      >
        <MenuItem value="mr">{t('mr')}</MenuItem>
        <MenuItem value="mme">{t('mme')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CiviliteSelect;
