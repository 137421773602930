import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { PublicationType } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['publicationType', 'publicationTypes'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getPublicationTypes: builder.query<PublicationType[], RequestQuery<PublicationType>>({
        queryFn: async q => {
          try {
            const data = await directus.request<PublicationType[]>(readItems('publicationType', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        keepUnusedDataFor: 3600,
        providesTags: ['publicationTypes'],
      }),
      getPublicationType: builder.query<
        PublicationType,
        { id: string; q?: RequestQuery<PublicationType> }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<PublicationType>(
              readItem('publicationType', q.id, q.q),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['publicationType'],
      }),
      createPublicationType: builder.mutation<
        PublicationType,
        {
          item: Partial<PublicationType>;
          query?: MutationQuery<PublicationType>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<PublicationType>(
              createItem('publicationType', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['publicationType', 'publicationTypes'],
      }),
      updatePublicationType: builder.mutation<
        PublicationType,
        {
          id: string;
          item: Partial<PublicationType>;
          query?: MutationQuery<PublicationType>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<PublicationType>(
              updateItem('publicationType', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['publicationType', 'publicationTypes'],
      }),
      deletePublicationType: builder.mutation<void, PublicationType[]>({
        queryFn: async publicationType => {
          try {
            const ids = publicationType.map(item => item.id);
            await directus.request(deleteItems('publicationType', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['publicationType', 'publicationTypes'],
      }),
    }),
  });

export const {
  useGetPublicationTypesQuery,
  useGetPublicationTypeQuery,
  useCreatePublicationTypeMutation,
  useUpdatePublicationTypeMutation,
  useDeletePublicationTypeMutation,
} = extendedApi;
