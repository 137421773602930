import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslate } from '@tolgee/react';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import DeleteUserBtn from 'modules/admin/users/DeleteUserBtn';
import { useMemo, useState } from 'react';
import { useGetUsersQuery } from 'services/endpoints/UsersEndpoints';
import PageTitle from 'shared/components/PageTitle';
import {
  getDirectusSort,
  useDirectusFilter,
  useGetDefaultMRTOptions,
} from 'shared/material-data-table/defaultMRTable';
import { DirectusUsers } from 'shared/model/types';

const columnHelper = createMRTColumnHelper<DirectusUsers>();

const UsersPage = () => {
  const { t } = useTranslate();
  const defaultMRTOptions = useGetDefaultMRTOptions<DirectusUsers>();

  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'email',
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const columns = useMemo(() => {
    const result = [
      /*columnHelper.accessor('id', {
        header: 'ID',
      }),*/
      columnHelper.accessor('first_name', {
        header: t('firstName'),
        grow: 1,
      }),
      columnHelper.accessor('last_name', {
        header: t('lastName'),
        grow: 1,
      }),
      columnHelper.accessor('email', {
        header: t('email'),
        grow: 1,
        Cell({ cell }) {
          const email = cell.getValue();
          if (email) {
            return <a href={`mailto:${email}`}>{email}</a>;
          }
          return null;
        },
      }),
      columnHelper.accessor('language', {
        header: t('language'),
        grow: 1,
      }),
      columnHelper.accessor('status', {
        header: t('status'),
        grow: 1,
        Cell({ cell }) {
          const status = cell.getValue();
          if (status === 'invited') {
            return <Box color="warning.light">{status}</Box>;
          } else if (status === 'active') {
            return <Box color="success.main">{status}</Box>;
          } else if (status === 'suspended') {
            return <Box color="error.main">{status}</Box>;
          } else if (status === 'archived') {
            return <Box color="grey.500">{status}</Box>;
          }
          return status;
        },
      }),
      columnHelper.accessor('role', {
        header: t('role'),
        grow: 1,
        Cell({ cell }) {
          const role = cell.getValue();
          if (isString(role)) {
            return <Box>{role}</Box>;
          } else if (isObject(role)) {
            return <Box>{t(`roles.${role.name}`)}</Box>;
          }
          return null;
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            label: t('roles.Administrator'),
            value: 'Administrator',
          },
          {
            label: t('roles.ROLE_USER'),
            value: 'ROLE_USER',
          },
        ],
        meta: {
          filterPath: 'role.name',
        },
      }),
    ];
    return result;
  }, [t]);

  const filter = useDirectusFilter<DirectusUsers>(columnFilters, columns);

  const { data, isLoading } = useGetUsersQuery({
    fields: [
      'id',
      'email',
      'first_name',
      'last_name',
      'status',
      'language',
      {
        role: ['name'],
      },
    ],
    sort: getDirectusSort(sorting),
    page: pagination.pageIndex,
    limit: pagination.pageSize,
    filter,
  });

  const users = data ? (data as DirectusUsers[]) : [];

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    renderTopToolbarCustomActions: ({ table }) => {
      const selection = table.getSelectedRowModel().flatRows.map(row => row.original);

      return (
        <>
          <Box flexGrow={1}>
            <PageTitle text={t('header.users')} icon={<GroupIcon fontSize="large" />} />
          </Box>
          {selection.length === 0 ? (
            <Tooltip title="Create New User">
              <IconButton>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <DeleteUserBtn users={selection} />
          )}
        </>
      );
    },
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    initialState: {
      ...defaultMRTOptions.initialState,
    },
    columns,
    data: users,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    enableGrouping: true,
    state: {
      showProgressBars: isLoading,
      sorting,
      pagination,
      columnFilters,
    },
  });

  return (
    <Box px={1}>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default UsersPage;
