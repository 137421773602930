import { AggregationsAggregationContainer } from '@elastic/elasticsearch/lib/api/types';
import get from 'lodash/get';
import { abstractFacets } from 'modules/search/config/abstractFacets';
import { personFacets } from 'modules/search/config/personFacets';
import { structureFacets } from 'modules/search/config/structureFacets';
import { teamFacets } from 'modules/search/config/teamFacets';

const defaultAggs: Record<string, AggregationsAggregationContainer> = {
  _index: {
    terms: {
      field: '_index',
    },
  },
};

const aggs = {
  default: defaultAggs,
  team: teamFacets,
  person: personFacets,
  structure: structureFacets,
  abstract: abstractFacets,
};

const getAggs = (index?: string) => {
  return get(aggs, index ?? 'default', defaultAggs);
};

export default getAggs;
