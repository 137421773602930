import { AggregationsAggregationContainer } from '@elastic/elasticsearch/lib/api/types';

export const abstractFacets: Record<string, AggregationsAggregationContainer> = {
  _index: {
    terms: {
      field: '_index',
      size: 30,
    },
    aggs: {
      type: {
        terms: {
          field: 'type.keyword',
          size: 30,
        },
      },
      'thematique.name': {
        terms: {
          field: 'thematique.name.keyword',
          size: 30,
        },
      },
    },
  },
};
