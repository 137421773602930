import { AggregationsStringTermsBucket } from '@elastic/elasticsearch/lib/api/types';
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import SearchFacets from 'modules/search/SearchFacets';
import { useTranslation } from 'react-i18next';
import ModelIcon from 'shared/model/def/ModelIcon';
import { setFrom, setIndexFilter, setQuery } from 'shared/reducers/searchSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

type Props = {
  bucket: AggregationsStringTermsBucket;
  disabled: boolean;
  currentAgg?: string;
  parentKey?: string;
};

const SearchIndexFacetItem = ({ bucket, parentKey, currentAgg, disabled }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const indexFilter = useAppSelector(state => state.search.indexFilter);
  const query = useAppSelector(state => state.search.query);

  const isRoot = !parentKey;

  const isBoolean = bucket.key_as_string === 'true' || bucket.key_as_string === 'false';
  const suffix = isBoolean ? '' : '.keyword';
  const bucketKey = isBoolean ? Boolean(bucket.key) : bucket.key;

  let isSelected = false;
  if (isRoot) {
    isSelected = indexFilter === bucket.key;
  } else {
    const must = query?.bool?.must ?? [];

    const termKey = currentAgg + suffix;

    if (Array.isArray(must)) {
      isSelected = must.some(i => {
        const found = i.terms && Object.keys(i.terms).includes(termKey);
        if (found) {
          const value = get(i.terms, termKey) as string[];
          return value.includes(bucketKey);
        }
        return false;
      });
    }
  }

  const onClick = () => {
    dispatch(setFrom(0));
    if (isRoot) {
      if (isSelected) {
        const newQuery = cloneDeep(query);

        const must = newQuery?.bool?.must ?? [];
        if (Array.isArray(must)) {
          const newMust = must.filter(i => !i.terms);
          dispatch(
            setQuery({
              ...newQuery,
              bool: {
                must: newMust,
              },
            }),
          );
          dispatch(setIndexFilter(''));
        }
      } else {
        dispatch(setIndexFilter(bucketKey));
      }
    } else if (currentAgg) {
      const newQuery = cloneDeep(query);

      const must = newQuery?.bool?.must ?? [];
      const termKey = currentAgg + suffix;

      if (Array.isArray(must)) {
        if (isSelected) {
          const index = must.findIndex(i => {
            const found = i.terms && Object.keys(i.terms).includes(termKey);
            if (found) {
              const value = get(i.terms, termKey) as string[];
              return value.includes(bucketKey);
            }
            return false;
          });
          if (index !== -1) {
            must.splice(index, 1);
          }
        } else {
          must.push({
            terms: {
              [termKey]: [bucketKey],
            },
          });
        }
      }

      dispatch(
        setQuery({
          ...newQuery,
          bool: {
            must,
          },
        }),
      );
    }
  };

  const othersKeys = Object.keys(bucket).filter(key => key !== 'key' && key !== 'doc_count');

  const others = othersKeys.reduce((agg: any[], current) => {
    const childBucket = get(bucket, current);
    if (childBucket?.buckets) {
      agg.push({
        key: current,
        ...childBucket,
      });
    }
    return agg;
  }, []);

  const i18nKey = isRoot ? bucket.key + 's' : parentKey + '.' + bucket.key;

  const txt = bucket.key_as_string
    ? t('searchFacet.' + bucket.key_as_string)
    : t('searchFacet.' + i18nKey, bucket.key);

  return (
    <>
      <ListItemButton onClick={onClick} selected={isSelected} disabled={disabled}>
        {isRoot && (
          <ListItemIcon sx={{ minWidth: 38 }}>
            <ModelIcon model={bucket.key} asAvatar fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Box display="flex" justifyContent="space-between">
              <Box>{txt.toString()}</Box>
              <Box color={isSelected ? 'white' : 'primary.main'} ml={1}>
                ({bucket.doc_count})
              </Box>
            </Box>
          }
        />
      </ListItemButton>
      {others.map(other => (
        <SearchFacets
          key={bucket.key + '-' + other.key}
          disabled={disabled}
          label={t('searchFacet.' + bucket.key + '.' + other.key)}
          agg={other}
          parentKey={bucket.key}
        />
      ))}
    </>
  );
};

export default SearchIndexFacetItem;
