import PersonForm from 'modules/sheet/person/PersonForm';
import { useUpdatePersonMutation } from 'services/endpoints/PersonEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { TeamFormType } from 'shared/model/def/team.def';
import { Person } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  item: Person;
};

const EditPersonBtn = ({ item }: Props) => {
  const dispatch = useAppDispatch();

  const [updateItem] = useUpdatePersonMutation();

  const onSubmit = async (newTeam: TeamFormType) => {
    await updateItem({
      id: item.id,
      item: newTeam,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton>
      <PersonForm item={item} onSubmit={onSubmit} />
    </OpenDrawerButton>
  );
};

export default EditPersonBtn;
