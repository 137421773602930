import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import EditableCard from 'shared/components/Card/EditableCard';
import DrawerTextEditor from 'shared/components/editor/DrawerTextEditor';
import EscapeTextEditor from 'shared/components/textEditor/EscapeTextEditor';

type Props = {
  title: string;
  content?: string | null;
  onValid: (content: string) => Promise<any>;
  disabled?: boolean;
};

const EditableCartTextEditor = ({ title, content, onValid, disabled = false }: Props) => {
  const { t } = useTranslate();

  const [newContent, setnewContent] = useState(content);

  const onContentChange = (content: string) => {
    setnewContent(content);
  };

  return (
    <EditableCard
      title={title}
      actions={
        <>
          <OpenDrawerButton>
            <DrawerTextEditor
              title={title}
              content={content}
              onValid={onValid}
              disabled={disabled}
            />
          </OpenDrawerButton>
        </>
      }
    >
      <EscapeTextEditor
        content={content}
        preview={true}
        onChange={onContentChange}
        disabled={disabled}
        autofocus
      />
    </EditableCard>
  );
};

export default EditableCartTextEditor;
