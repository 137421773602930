import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import random from 'lodash/random';
import AuthorFormDialog from 'modules/sheet/abstract/authors/AuthorFormDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AbstractAuthor, AbstractAuthorStructure } from 'shared/model/types';

const defaultAuthor: Partial<AbstractAuthor> = {
  civilite: 'mr',
  firstName: '',
  lastName: '',
  speaker: false,
  structures: [],
};

type Props = {
  sort: number;
  onCreated: (author: Partial<AbstractAuthor>) => void;
  existingStructures?: AbstractAuthorStructure[];
};

const AddAuthorBtn = ({ onCreated, sort, existingStructures }: Props) => {
  const { t } = useTranslation();

  const [showDialog, setShowDialog] = useState(false);

  const onClick = () => {
    setShowDialog(true);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const onConfirm = (author: Partial<AbstractAuthor>) => {
    setShowDialog(false);

    onCreated({
      ...author,
      id: random(-100000, -1),
    });
  };

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={onClick} size="small">
        {t('abstract.addAuthor')}
      </Button>
      {showDialog && (
        <AuthorFormDialog
          author={{
            ...defaultAuthor,
            sort,
          }}
          existingStructures={existingStructures}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default AddAuthorBtn;
