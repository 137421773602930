import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Dialog, useMediaQuery, useTheme } from '@mui/material';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import { useTranslate } from '@tolgee/react';
import { useUpdateTeamMutation } from 'services/endpoints/TeamEndpoints';
import { Team } from 'shared/model/types';

type Props = {
  structureId: number;
  teamsToAdd: Team[];
  teamsToRemove: Team[];
  onClose: () => void;
};

const ConfirmTeamSelection = ({ structureId, teamsToAdd, teamsToRemove, onClose }: Props) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [updateTeam] = useUpdateTeamMutation();

  const onConfirm = async () => {
    for (const team of teamsToAdd) {
      await updateTeam({
        id: team.id,
        item: {
          structure: structureId,
        },
      }).unwrap();
    }

    for (const team of teamsToRemove) {
      await updateTeam({
        id: team.id,
        item: {
          structure: null,
        },
      }).unwrap();
    }

    onClose();
  };

  return (
    <Dialog open fullScreen={fullScreen}>
      <DialogTitle>{t('askConfirmation')}</DialogTitle>
      <DialogContent>
        {teamsToAdd.length > 0 && (
          <Box>
            <Box color="green">{t('structure.teamConfirmAdd')}</Box>
            <ul>
              {teamsToAdd.map(team => (
                <li key={team.id}>{team.name}</li>
              ))}
            </ul>
          </Box>
        )}
        {teamsToRemove.length > 0 && (
          <Box>
            <Box color="red">{t('structure.teamConfirmRemove')}</Box>
            <ul>
              {teamsToRemove.map(team => (
                <li key={team.id}>{team.name}</li>
              ))}
            </ul>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose} startIcon={<CancelIcon />}>
          {t('cancel')}
        </Button>
        <Button color="primary" onClick={onConfirm} startIcon={<CheckIcon />}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTeamSelection;
