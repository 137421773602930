import { Box, TextField } from '@mui/material';
import usePersonResolver from 'modules/sheet/person/usePersonResolver';
import { MuiTelInput } from 'mui-tel-input';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import CiviliteSelect from 'shared/components/select/CiviliteSelect';
import { CiviliteType, PersonFormType } from 'shared/model/def/person.def';
import { TeamFormType, TeamIcon } from 'shared/model/def/team.def';
import { Person } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  item: Partial<Person>;
  disabled?: boolean;
  onSubmit: (team: TeamFormType) => void;
};

const PersonForm = ({ item, disabled = false, onSubmit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resolver = usePersonResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonFormType>({
    defaultValues: {
      ...item,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const titleI18n = item.id ? 'person.edit' : 'person.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleI18n} icon={<TeamIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="civilite"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field: { onChange, value } }) => (
                <CiviliteSelect
                  value={value as CiviliteType}
                  onChange={onChange}
                  error={!!errors.civilite}
                  helperText={errors.civilite?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field }) => (
                <TextField
                  label={t('firstName')}
                  {...field}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field }) => (
                <TextField
                  label={t('lastName')}
                  {...field}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="phone"
              control={control}
              render={({ field: { ref: fieldRef, value, ...rest } }) => (
                <MuiTelInput
                  {...rest}
                  label={t('phone')}
                  defaultCountry="FR"
                  value={value ?? ''}
                  inputRef={fieldRef}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="portable"
              control={control}
              render={({ field: { ref: fieldRef, value, ...rest } }) => (
                <MuiTelInput
                  {...rest}
                  label={t('portable')}
                  defaultCountry="FR"
                  value={value ?? ''}
                  inputRef={fieldRef}
                  error={!!errors.portable}
                  helperText={errors.portable?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="fax"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('fax')}
                  {...field}
                  error={!!errors.fax}
                  helperText={errors.fax?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('email')}
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="website"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('webSite')}
                  {...field}
                  error={!!errors.website}
                  helperText={errors.website?.message}
                />
              )}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default PersonForm;
