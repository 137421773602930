import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslate } from '@tolgee/react';
import isObject from 'lodash/isObject';
import { TeamMemberFormType } from 'modules/sheet/team/teamMember/teamMember.def';
import { Metier, Person, Statut } from 'shared/model/types';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useTeamMemberResolver = () => {
  const { t } = useTranslate();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    person: z.custom<Person>(value => isObject(value), { message: requiredMessage }),
    metier: z.custom<Metier>(value => isObject(value), { message: requiredMessage }),
    statut: z.custom<Statut>(value => isObject(value), { message: requiredMessage }),
    label: z.optional(z.string()),
    startDate: z.optional(z.any()),
    endDate: z.optional(z.any()),
  }) satisfies ZodType<TeamMemberFormType>;

  return zodResolver(schema);
};
export default useTeamMemberResolver;
