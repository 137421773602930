import { Box, Paper } from '@mui/material';
import DisplayHtml from 'shared/components/DisplayHtml';
import { Publication } from 'shared/model/types';

type Props = {
  publication: Publication;
};

const DisplayPublication = ({ publication }: Props) => {
  return (
    <Box mb={2}>
      <Paper elevation={3}>
        <Box p={2}>
          <Box component="h3">{publication.title}</Box>
          <DisplayHtml html={publication.content ?? ''} />
        </Box>
      </Paper>
    </Box>
  );
};

export default DisplayPublication;
