import { Box } from '@mui/material';
import { useGetStructure } from 'modules/sheet/sheetHook';
import DeleteStructuresBtn from 'modules/sheet/structure/DeleteStructuresBtn';
import { useNavigate } from 'react-router-dom';
import IconValue from 'shared/components/IconValue';
import Loader from 'shared/components/Loader';
import SheetContent from 'shared/components/sheet/SheetContent';
import SheetTitle from 'shared/components/sheet/SheetTitle';
import { useSaveHistory } from 'shared/hooks/historyHook';
import ModelName from 'shared/model/def/ModelName';
import { StructureIcon } from 'shared/model/def/structure.def';

const tabs = [
  {
    label: 'sheetStructure.tabMain',
    path: 'main',
  },
  {
    label: 'sheetStructure.tabPresentation',
    path: 'presentation',
  },
  {
    label: 'sheetStructure.tabPublications',
    path: 'publications',
  },
  {
    label: 'team.team_other',
    path: 'teams',
  },
  {
    label: 'sheetTeam.tabEmployes',
    path: 'employes',
  },
];

type Props = {
  children: React.ReactNode;
};

const StructureSheet = ({ children }: Props) => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetStructure();

  useSaveHistory('structure', data?.id, data?.name);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const onDeleted = () => {
    navigate('/search');
  };

  return (
    <Box px={1}>
      <SheetTitle actions={<DeleteStructuresBtn ids={[data.id]} onDeleted={onDeleted} />}>
        <IconValue icon={<StructureIcon fontSize="large" />}>
          <ModelName model={data} type="structure" fontSize={24} />
        </IconValue>
      </SheetTitle>
      <SheetContent tabs={tabs} matchPath="/sheet/structure/:id/:tab">
        {children}
      </SheetContent>
    </Box>
  );
};

export default StructureSheet;
