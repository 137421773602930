import { Text } from '@react-pdf/renderer';
import { AuthorStructureRank } from 'modules/sheet/abstract/AbstractHook';

type Props = {
  item: AuthorStructureRank;
  showRank?: boolean;
};

const AuthorStructurePdf = ({ item, showRank = false }: Props) => {
  return (
    <Text
      style={{
        marginBottom: 5,
      }}
    >
      {showRank && (
        <Text
          style={{
            verticalAlign: 'super',
            fontSize: 8,
          }}
        >
          {item.rank}&nbsp;
        </Text>
      )}
      <Text>{item.name}</Text>
    </Text>
  );
};

export default AuthorStructurePdf;
