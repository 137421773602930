import CheckIcon from '@mui/icons-material/Check';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useTranslate } from '@tolgee/react';
import { useUpdateAbstractAuthorMutation } from 'services/endpoints/AbstractEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';
import { AbstractAuthor } from 'shared/model/types';

type Props = {
  author: AbstractAuthor;
};

const UnLinkAuthorBtn = ({ author }: Props) => {
  const { t } = useTranslate();

  const [updateAuthor, { isLoading }] = useUpdateAbstractAuthorMutation();

  const onConfirm = async () => {
    await updateAuthor({
      id: author.id,
      item: {
        person: null,
      },
    });
  };

  return (
    <ConfirmDelete
      color="primary"
      startIcon={<CheckIcon />}
      text={t('confirm')}
      dialogTitle={t('disociate')}
      dialogText={t('abstract.disociateAuthorConfirm')}
      onConfirm={onConfirm}
      disabled={isLoading}
      onlyIcon
      icon={<OpenInFullIcon color="info" />}
    />
  );
};

export default UnLinkAuthorBtn;
