import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { Box } from '@mui/material';
import AbstractEditableAuthorItem from 'modules/sheet/abstract/authors/AbstractEditableAuthorItem';
import AddAuthorBtn from 'modules/sheet/abstract/authors/AddAuthorBtn';
import { useState } from 'react';
import { AbstractAuthor, AbstractAuthorStructure } from 'shared/model/types';
import { reorder, sortBySort } from 'shared/utils/array-utils';

type Props = {
  value: Partial<AbstractAuthor>[];
  disabled?: boolean;
  onChange?: (authors: Partial<AbstractAuthor>[]) => void;
};

const AbstractEditableAuthors = ({ value, onChange, disabled }: Props) => {
  const [orderedList, setOrderedList] = useState<Partial<AbstractAuthor>[]>([...value]);

  const onDragEnd = (result: DropResult) => {
    if (disabled) {
      return;
    }
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder = reorder<Partial<AbstractAuthor>>(
      orderedList,
      result.source.index,
      result.destination.index,
    ).map((field, index) => ({
      ...field,
      sort: index,
    }));

    setOrderedList(newOrder);
    if (onChange) {
      onChange(newOrder);
    }
  };

  const onAddAuthor = (author: Partial<AbstractAuthor>) => {
    author.sort = orderedList.length;
    const newOrder = [...orderedList, author];
    newOrder.sort(sortBySort());
    setOrderedList(newOrder);
    if (onChange) {
      onChange(newOrder);
    }
  };

  const onRemoveAuthor = (author: Partial<AbstractAuthor>) => {
    const newOrder = orderedList.filter(a => a.id !== author.id);
    newOrder.sort(sortBySort());
    setOrderedList(newOrder);
    if (onChange) {
      onChange(newOrder);
    }
  };

  const onEditAuthor = (author: Partial<AbstractAuthor>) => {
    const removed = orderedList.filter(a => a.id !== author.id);
    const newOrder = [...removed, author];
    newOrder.sort(sortBySort());
    setOrderedList(newOrder);
    if (onChange) {
      onChange(newOrder);
    }
  };

  const existingStructures = orderedList.reduce((acc: AbstractAuthorStructure[], current) => {
    if (current.structures) {
      const currentExisting = current.structures as AbstractAuthorStructure[];
      currentExisting.forEach(s => {
        const found = acc.find(a => a.name === s.name);
        if (!found) {
          acc.push(s);
        }
      });
    }
    return acc;
  }, []);

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="authors-list">
          {provided => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {orderedList.map((item, index) => {
                return (
                  <AbstractEditableAuthorItem
                    key={item.id ?? item.lastName}
                    item={item}
                    index={index}
                    existingStructures={existingStructures}
                    onRemove={onRemoveAuthor}
                    onEdit={onEditAuthor}
                  />
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box textAlign="center">
        <AddAuthorBtn
          onCreated={onAddAuthor}
          sort={orderedList.length}
          existingStructures={existingStructures}
        />
      </Box>
    </Box>
  );
};

export default AbstractEditableAuthors;
