import { Draggable } from '@hello-pangea/dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditAuthorBtn from 'modules/sheet/abstract/authors/EditAuthorBtn';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AbstractAuthor, AbstractAuthorStructure } from 'shared/model/types';

type Props = {
  item: Partial<AbstractAuthor>;
  index: number;
  existingStructures?: AbstractAuthorStructure[];
  onRemove?: (item: Partial<AbstractAuthor>) => void;
  onEdit?: (item: Partial<AbstractAuthor>) => void;
};

const AbstractEditableAuthorItem = ({
  item,
  index,
  existingStructures,
  onRemove,
  onEdit,
}: Props) => {
  const { t } = useTranslation();
  const structures = item.structures ? (item.structures as AbstractAuthorStructure[]) : [];

  const [showActions, setShowActions] = useState(false);

  const onMouseEnter = () => {
    setShowActions(true);
  };

  const onMouseLeave = () => {
    setShowActions(false);
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(item);
    }
  };

  const handleEdit = (author: Partial<AbstractAuthor>) => {
    if (onEdit) {
      onEdit({
        id: item.id,
        ...author,
      });
    }
  };

  const civilite = item.civilite ? t(item.civilite) : '';

  return (
    <Draggable key={item.id} draggableId={item.id + ''} index={index}>
      {provided => (
        <Box
          sx={{
            padding: 1,
            border: '1px solid #ccc',
            marginBottom: 1,
            display: 'flex',
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Box {...provided.dragHandleProps} pr={1} pt={0.2}>
            <DragIndicatorIcon />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Box>
              <Box fontWeight={item.speaker ? 'bold' : 'normal'}>
                {civilite && (
                  <Box mr={1} display="inline">
                    {civilite}
                  </Box>
                )}
                {item.firstName} {item.lastName}
              </Box>
              <Box fontSize="small">
                {structures.map(structure => (
                  <Box key={structure.id}>{structure.name}</Box>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                visibility: showActions ? 'visible' : 'hidden',
              }}
            >
              <EditAuthorBtn
                item={item}
                onEdit={handleEdit}
                existingStructures={existingStructures}
              />
              {onRemove && (
                <IconButton onClick={handleRemove} color="error">
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default AbstractEditableAuthorItem;
