import Point from '@arcgis/core/geometry/Point';
import { useUpdateTeamMutation } from 'services/endpoints/TeamEndpoints';
import ShowAndEditGeom from 'shared/components/map/ShowAndEditGeom';
import { getTeamAdress } from 'shared/model/def/team.def';

import { Team } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  team: Team;
  className?: string;
};

const TeamEditGeom = ({ team, className }: Props) => {
  const dispatch = useAppDispatch();
  const [updateTeam] = useUpdateTeamMutation();

  const onSaveGeom = async (point: Point) => {
    updateTeam({
      id: team.id,
      item: {
        geom: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      },
    }).unwrap();
    dispatch(closeAllDrawers());
  };

  const address = getTeamAdress(team);

  return (
    <ShowAndEditGeom
      defaultAddress={address}
      point={team.geom}
      onSave={onSaveGeom}
      className={className}
    />
  );
};

export default TeamEditGeom;
