import InfoIcon from '@mui/icons-material/Info';
import { t } from 'i18next';
import SearchResultTeam from 'modules/search/results/SearchResultTeam';
import EditTeamBtn from 'modules/sheet/team/EditTeamBtn';
import EditableCard from 'shared/components/Card/EditableCard';
import IconValue from 'shared/components/IconValue';
import { Team } from 'shared/model/types';

type Props = {
  team: Team;
};

const TeamInfo = ({ team }: Props) => {
  return (
    <EditableCard
      title={<IconValue icon={<InfoIcon />}>{t('contact')}</IconValue>}
      actions={<EditTeamBtn team={team} />}
    >
      <SearchResultTeam team={team} noDescription />
    </EditableCard>
  );
};

export default TeamInfo;
