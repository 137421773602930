import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { useTranslate } from '@tolgee/react';
import { ReactNode, useState } from 'react';

interface Props {
  onConfirm: () => void;
  onlyIcon?: boolean;
  noText?: boolean;
  icon?: ReactNode;
  text?: string;
  dialogTitle?: string;
  dialogText?: string;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  size?: 'small' | 'medium' | 'large';
  startIcon?: ReactNode;
}

const ConfirmDelete = (props: Props) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    onlyIcon = false,
    icon = <DeleteIcon />,
    disabled = false,
    text = t('delete'),
    dialogTitle = t('confirmDelete.title'),
    dialogText = t('confirmDelete.content'),
    size,
    color = 'error',
    noText = false,
    startIcon = <DeleteIcon />,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    props.onConfirm();
  };

  return (
    <>
      {onlyIcon ? (
        <IconButton color="error" onClick={handleClickOpen} disabled={disabled}>
          {icon}
        </IconButton>
      ) : noText ? (
        <Button
          color="error"
          size={size}
          onClick={handleClickOpen}
          sx={{ marginLeft: 1 }}
          disabled={disabled}
        >
          {icon}
        </Button>
      ) : (
        <Button
          color="error"
          startIcon={icon}
          size={size}
          onClick={handleClickOpen}
          sx={{ marginLeft: 1 }}
          disabled={disabled}
        >
          {text}
        </Button>
      )}
      <Dialog open={open} fullScreen={fullScreen}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={color} variant="outlined" onClick={handleClose} startIcon={<CancelIcon />}>
            {t('cancel')}
          </Button>
          <Button color={color} onClick={onConfirm} startIcon={startIcon}>
            {text}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
