import Point from '@arcgis/core/geometry/Point';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslate } from '@tolgee/react';
import { Feature } from 'geojson';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
type Props = {
  defaultAddress?: string;
  onSelection: (point: Point) => void;
};

const LocationSearch = ({ defaultAddress, onSelection }: Props) => {
  const { t } = useTranslate();
  const [value, setValue] = useState<Feature | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Feature[]>([]);
  const [debouncedValue, setDebouncedValue] = useState('');
  const [loading, setLoading] = useState(false);

  useDebounce(
    () => {
      setDebouncedValue(inputValue);
    },
    300,
    [inputValue],
  );

  useEffect(() => {
    if (defaultAddress) {
      setInputValue(defaultAddress);
    }
  }, [defaultAddress]);

  useEffect(() => {
    const loadOptions = () => {
      setLoading(true);
      const searchUrlApi = `https://api-adresse.data.gouv.fr/search/?q=${debouncedValue}&limit=15`;

      fetch(searchUrlApi)
        .then(response => response.json())
        .then(data => {
          setOptions(data.features);
          if (data.features.length === 1 && debouncedValue === defaultAddress) {
            setValue(data.features[0]);
          }
        })
        .catch(e => {
          setOptions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (debouncedValue?.length > 2) {
      loadOptions();
    } else {
      setOptions([]);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (value) {
      const geom = value.geometry;
      if (geom.type === 'Point') {
        onSelection(
          new Point({
            longitude: geom.coordinates[0],
            latitude: geom.coordinates[1],
          }),
        );
      }
    }
  }, [value, onSelection]);

  return (
    <Autocomplete
      autoComplete
      filterOptions={x => x}
      options={options}
      noOptionsText={t('noResult')}
      loading={loading}
      loadingText={t('loading')}
      getOptionLabel={option => {
        const properties = option.properties;
        return properties?.label;
      }}
      isOptionEqualToValue={(option, value) => {
        const optProperties = option.properties;
        const valueProperties = value.properties;
        return optProperties?.label === valueProperties?.label;
      }}
      value={value}
      onChange={(event: any, newValue: Feature | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField autoFocus={!defaultAddress} {...params} label={t('searchLocation')} fullWidth />
      )}
      renderOption={(props, option) => {
        const properties = option.properties;
        return (
          <li {...props} key={properties?.label}>
            {properties?.label}
          </li>
        );
      }}
    />
  );
};

export default LocationSearch;
