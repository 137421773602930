import { Box } from '@mui/material';
import { useGetAbstractAuthors } from 'modules/sheet/abstract/AbstractHook';
import AbstractAuthorItem from 'modules/sheet/abstract/authors/AbstractAuthorItem';
import { Abstract } from 'shared/model/types';

type Props = {
  abstract: Abstract;
  showRank?: boolean;
  showIcon?: boolean;
  inline?: boolean;
  showStructure?: boolean;
  showLink?: boolean;
  showLinkToPerson?: boolean;
  showCivilite?: boolean;
};

const AbstractAuthors = ({
  abstract,
  showRank = false,
  inline = false,
  showIcon = false,
  showStructure = false,
  showLink = false,
  showLinkToPerson = false,
  showCivilite = false,
}: Props) => {
  const authors = useGetAbstractAuthors(abstract);

  if (authors.length === 0) {
    return null;
  }

  return (
    <Box display={inline ? 'flex' : 'block'} width="100%" flexWrap="wrap">
      {authors.map((author, index) => {
        const suffix = inline && index < authors.length - 1 ? ', ' : '';
        return (
          <AbstractAuthorItem
            key={author.id}
            item={author}
            abstract={abstract}
            showRank={showRank}
            suffix={suffix}
            showIcon={showIcon}
            showLink={showLink}
            showStructure={showStructure}
            showLinkToPerson={showLinkToPerson}
            showCivilite={showCivilite}
          />
        );
      })}
    </Box>
  );
};

export default AbstractAuthors;
