import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Thematique } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['thematique', 'thematiques'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getThematiques: builder.query<Thematique[], RequestQuery<Thematique>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Thematique[]>(readItems('thematique', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['thematiques'],
      }),
      getThematique: builder.query<Thematique, { id: string; q?: RequestQuery<Thematique> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Thematique>(readItem('thematique', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['thematique'],
      }),
      createThematique: builder.mutation<
        Thematique,
        {
          item: Partial<Thematique>;
          query?: MutationQuery<Thematique>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Thematique>(
              createItem('thematique', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['thematique', 'thematiques'],
      }),
      updateThematique: builder.mutation<
        Thematique,
        {
          id: string;
          item: Partial<Thematique>;
          query?: MutationQuery<Thematique>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Thematique>(
              updateItem('thematique', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['thematique', 'thematiques'],
      }),
      deleteThematique: builder.mutation<void, Thematique[]>({
        queryFn: async thematique => {
          try {
            const ids = thematique.map(item => item.id);
            await directus.request(deleteItems('thematique', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['thematique', 'thematiques'],
      }),
    }),
  });

export const {
  useGetThematiquesQuery,
  useGetThematiqueQuery,
  useCreateThematiqueMutation,
  useUpdateThematiqueMutation,
  useDeleteThematiqueMutation,
} = extendedApi;
