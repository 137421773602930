import { Box, Card, CardContent } from '@mui/material';
import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
  actions?: React.ReactNode;
};

const SheetTitle = ({ children, actions }: Props) => {
  const [showActions, setShowActions] = useState(false);

  const onShowActions = () => {
    setShowActions(true);
  };

  const onHideActions = () => {
    setShowActions(false);
  };
  return (
    <Box pb={1} onMouseEnter={onShowActions} onMouseLeave={onHideActions}>
      <Card>
        <CardContent sx={{ paddingBottom: '16px !important' }}>
          <Box display="flex" alignItems="flex-start">
            <Box mr={1}>{children}</Box>
            <Box
              display="flex"
              sx={{
                visibility: showActions ? 'visible' : 'hidden',
              }}
            >
              {actions}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SheetTitle;
