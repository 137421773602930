import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Grid2 as Grid } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars';
import DisplayHtml from 'shared/components/DisplayHtml';
import IconValue from 'shared/components/IconValue';
import { Project } from 'shared/model/types';
import { stringHtmlHasContent } from 'shared/utils/string-utils';

type Props = {
  project: Project;
  noDescription?: boolean;
};

const SearchResultProject = ({ project, noDescription = false }: Props) => {
  const md = noDescription ? 12 : 6;
  const lg = noDescription ? 12 : 4;
  const xl = noDescription ? 12 : 4;

  return (
    <Grid container alignItems="flex-start" spacing={1} fontSize="0.9rem">
      <Grid
        size={{
          xs: 12,
          md: md,
          lg: lg,
          xl: xl,
        }}
      >
        {project.acronym && (
          <IconValue icon={<CategoryIcon fontSize="small" />}>
            <Box>{project.acronym}</Box>
          </IconValue>
        )}
        {project.keyword && (
          <IconValue icon={<HomeIcon fontSize="small" />}>
            <Box>{project.keyword}</Box>
          </IconValue>
        )}
        {project.partner && (
          <IconValue icon={<PhoneIcon fontSize="small" />}>
            <Box pt={0.2}>{project.partner}</Box>
          </IconValue>
        )}
      </Grid>
      {!noDescription && (
        <Grid
          size={{
            xs: 12,
            md: 6,
            lg: 8,
            xl: 8,
          }}
        >
          {project.description && stringHtmlHasContent(project.description) && (
            <Scrollbars autoHide autoHeight autoHeightMax="260px">
              <DisplayHtml html={project.description} />
            </Scrollbars>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchResultProject;
