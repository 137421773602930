import { QueryFields } from '@directus/sdk';
import { Autocomplete, Box, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import isObject from 'lodash/isObject';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { useGetPersonsQuery } from 'services/endpoints/PersonEndpoints';
import { CustomDirectusTypes, Person } from 'shared/model/types';

type Props = {
  label?: string;
  defaultInputValue?: string;
  fields?: QueryFields<CustomDirectusTypes, Person>;
  value: Person | null;
  onChange: (person: Person | null) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const PersonAutocomplete = ({
  value,
  onChange,
  error,
  fields = ['id', 'civilite', 'firstName', 'lastName'],
  helperText,
  disabled,
  label,
  defaultInputValue,
}: Props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(defaultInputValue ?? '');
  const [debouncedValue, setDebouncedValue] = useState<any>();

  useDebounce(
    () => {
      const words = inputValue.split(' ');

      if (words.length > 1) {
        const filters = [
          {
            firstName: { _icontains: `%${words[0]}%` },
            lastName: { _icontains: `%${words[1]}%` },
          },
          {
            firstName: { _icontains: `%${words[1]}%` },
            lastName: { _icontains: `%${words[0]}%` },
          },
          {
            firstName: { _icontains: `%${inputValue}%` },
          },
          {
            lastName: { _icontains: `%${inputValue}%` },
          },
        ];
        setDebouncedValue(filters);
      } else {
        const filters = [
          { firstName: { _icontains: `%${inputValue}%` } },
          { lastName: { _icontains: `%${inputValue}%` } },
        ];
        setDebouncedValue(filters);
      }
    },
    500,
    [inputValue],
  );

  const { data, isLoading } = useGetPersonsQuery(
    {
      fields,
      sort: ['firstName', 'lastName'],
      filter: {
        _or: debouncedValue,
      },
    },
    {
      skip: !debouncedValue,
    },
  );

  const onSelection = (e: SyntheticEvent, value: Person | null) => {
    onChange(value);
  };

  const persons = data || [];

  const finalLabel = label ?? t('person.search');

  return (
    <Autocomplete
      autoComplete
      value={value ?? null}
      getOptionLabel={opt => (isObject(opt) ? opt?.firstName + ' ' + opt?.lastName : '')}
      getOptionKey={opt => (isObject(opt) ? opt?.id : '')}
      renderInput={params => (
        <TextField
          {...params}
          value={value}
          label={finalLabel}
          fullWidth
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
      options={persons}
      loading={isLoading}
      filterOptions={x => x}
      onChange={onSelection}
      includeInputInList
      noOptionsText={t('person.noResults')}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue.trim());
      }}
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const { key, ...optionProps } = props;

        const firstName = option.firstName ?? '';
        const lastName = option.lastName ?? '';

        const optionToSearch = firstName + '&nbsp;' + lastName;

        const matches = match(optionToSearch, inputValue, { insideWords: true });
        const parts = parse(optionToSearch, matches);

        return (
          <li key={key} {...optionProps}>
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  fontWeight: part.highlight ? 'bold' : 'regular',
                  whiteSpace: 'pre-wrap',
                }}
                dangerouslySetInnerHTML={{ __html: part.text }}
              ></Box>
            ))}
          </li>
        );
      }}
    ></Autocomplete>
  );
};

export default PersonAutocomplete;
