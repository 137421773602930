import { Box, Card, CardContent, CardHeader, Paper } from '@mui/material';
import { ReactNode, useState } from 'react';

type Props = {
  title: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

const EditableCard = ({ children, title, actions }: Props) => {
  const [showActions, setShowActions] = useState(false);

  const onShowActions = () => {
    setShowActions(true);
  };

  const onHideActions = () => {
    setShowActions(false);
  };

  return (
    <Paper elevation={3} onMouseEnter={onShowActions} onMouseLeave={onHideActions}>
      <Card>
        <CardHeader
          sx={{
            p: 1.2,
            '& .MuiCardHeader-title ': {
              fontSize: '1.1rem',
            },
          }}
          title={<Box fontWeight={500}>{title}</Box>}
          action={
            <Box
              sx={{
                visibility: showActions ? 'visible' : 'hidden',
              }}
            >
              {actions}
            </Box>
          }
        />
        <CardContent sx={{ p: 1.5, pt: 0 }}>{children}</CardContent>
      </Card>
    </Paper>
  );
};

export default EditableCard;
