import { Box } from '@mui/material';
import { useGetAbstractStructuresRanks } from 'modules/sheet/abstract/AbstractHook';
import AbstractStructureItem from 'modules/sheet/abstract/AbstractStructureItem';
import { Abstract } from 'shared/model/types';

type Props = {
  abstract: Abstract;
  showRank?: boolean;
  inline?: boolean;
};

const AbstractStructures = ({ abstract, showRank = true, inline = false }: Props) => {
  const structures = useGetAbstractStructuresRanks(abstract);

  if (structures.length === 0) {
    return null;
  }

  const displayRanks = structures.length > 1 && showRank;

  return (
    <Box display={inline ? 'flex' : 'block'} width="100%">
      {structures.map((s, index) => {
        const suffix = inline && index < structures.length - 1 ? ', ' : '';
        return (
          <AbstractStructureItem key={s.id} item={s} suffix={suffix} showRank={displayRanks} />
        );
      })}
    </Box>
  );
};

export default AbstractStructures;
