import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import {
  useGetAbstractAuthors,
  useGetAbstractStructuresRanks,
  useGetAuthorsRanks,
} from 'modules/sheet/abstract/AbstractHook';
import AbstractPdfContent from 'modules/sheet/abstract/pdf/AbstractPdfContent';
import AuthorPdf from 'modules/sheet/abstract/pdf/AuthorPdf';
import AuthorStructurePdf from 'modules/sheet/abstract/pdf/AuthorStructurePdf';
import { Abstract } from 'shared/model/types';
import { registerPDFFonts } from 'shared/utils/pdf-utils';

registerPDFFonts();

const pageMargin = 50;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  informations: {
    marginLeft: pageMargin,
    marginRight: pageMargin,
    marginTop: pageMargin + 35,
    marginBottom: 20,
  },
  content: {
    marginLeft: pageMargin,
    marginRight: pageMargin,
    textAlign: 'justify',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    marginBottom: 20,
  },
  reference: {
    fontSize: 18,
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  authors: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: 1,
  },
  structures: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  rank: {
    verticalAlign: 'super',
  },
});

type Props = {
  abstract: Abstract;
};

const AbstractPDF = ({ abstract }: Props) => {
  const authors = useGetAbstractAuthors(abstract);
  const authorsRanks = useGetAuthorsRanks(abstract);
  const structuresRanks = useGetAbstractStructuresRanks(abstract);

  const authorsStr = authors.map(author => author.firstName + ' ' + author.lastName).join(', ');
  const keywordsStr = abstract.theme ?? '';

  const showRank = structuresRanks.length > 1;

  return (
    <Document title={abstract.name} author={authorsStr} keywords={keywordsStr}>
      <Page size="A4" style={styles.page}>
        <View style={styles.informations}>
          <Text style={styles.reference}>{abstract.reference}</Text>
          <Text style={styles.title}>{abstract.name}</Text>
          <Text style={styles.authors}>
            {authorsRanks.map((ar, index) => (
              <AuthorPdf
                key={ar.aa.id}
                item={ar}
                last={index === authorsRanks.length - 1}
                showRank={showRank}
              />
            ))}
          </Text>
          <View style={styles.structures}>
            {structuresRanks.map(sr => (
              <AuthorStructurePdf key={sr.id} item={sr} showRank={showRank} />
            ))}
          </View>
        </View>
        <View style={styles.content}>
          <AbstractPdfContent abstract={abstract} html />
        </View>
      </Page>
    </Document>
  );
};

export default AbstractPDF;
