import { Box, Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import AbstractStructures from 'modules/sheet/abstract/AbstractStructures';
import AbstractAuthors from 'modules/sheet/abstract/authors/AbstractAuthors';
import { useTranslation } from 'react-i18next';
import DisplayHtml from 'shared/components/DisplayHtml';
import { Abstract, Thematique } from 'shared/model/types';

type Props = {
  abstract: Abstract;
};

const DisplayAbstract = ({ abstract }: Props) => {
  const { t } = useTranslation();
  const thematique = abstract.thematique as Thematique | undefined;

  const theme = abstract.theme;

  return (
    <Paper elevation={4}>
      <Box p={2}>
        <Box textAlign="center">
          <Typography variant="h5">{abstract.name}</Typography>
        </Box>
        {thematique && <Box mt={2}>{thematique.name}</Box>}
        {theme && (
          <Box mt={2} fontWeight="bold">
            {t('abstract.theme')}: {theme}
          </Box>
        )}
        <Box mt={2}>
          <AbstractAuthors abstract={abstract} inline showRank />
        </Box>
        <Box my={2}>
          <AbstractStructures abstract={abstract} showRank />
        </Box>
        <Divider />
        <Box mt={2}>
          <DisplayHtml html={abstract.content ?? ''} />
        </Box>
      </Box>
    </Paper>
  );
};

export default DisplayAbstract;
