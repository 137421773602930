import '@arcgis/core/assets/esri/themes/dark/main.css';
import esriConfig from '@arcgis/core/config';
import '@fontsource/montserrat';
import '@fontsource/poppins';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'config/directus';

import { TolgeeProvider } from '@tolgee/react';
import tolgee from 'config/i18n';
import 'dayjs/locale/fr';
import LocalizedApp from 'LocalizedApp';
import ReactDOM from 'react-dom/client';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from 'store';
import './index.scss';

const basePath = import.meta.env.BASE_URL;

esriConfig.assetsPath = basePath + 'assets';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <TolgeeProvider
    tolgee={tolgee}
    fallback="Loading..." // loading fallback
  >
    <HelmetProvider>
      <Provider store={store}>
        <LocalizedApp />
      </Provider>
    </HelmetProvider>
  </TolgeeProvider>,
);
