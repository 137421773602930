import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';
import isObject from 'lodash/isObject';
import { useGetAuthorsRanks } from 'modules/sheet/abstract/AbstractHook';
import LinkAuthorToPersonBtn from 'modules/sheet/abstract/authors/LinkAuthorToPersonBtn';
import UnLinkAuthorBtn from 'modules/sheet/abstract/authors/UnLinkAuthorBtn';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Abstract, AbstractAuthor, AbstractAuthorStructure, Person } from 'shared/model/types';

type Props = {
  item: AbstractAuthor;
  abstract: Abstract;
  showIcon?: boolean;
  showRank?: boolean;
  showLink?: boolean;
  suffix?: string;
  showStructure?: boolean;
  showLinkToPerson?: boolean;
  showCivilite?: boolean;
};

const AbstractAuthorItem = ({
  item,
  abstract,
  showRank = false,
  showLink = false,
  suffix = '',
  showIcon,
  showStructure = false,
  showLinkToPerson = false,
  showCivilite = false,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ranks = useGetAuthorsRanks(abstract);

  const nbDistincRanks = ranks.reduce((acc: number[], rank) => {
    rank.ranks.forEach(r => {
      if (!acc.includes(r.id)) {
        acc.push(r.id);
      }
    });

    return acc;
  }, []).length;

  const rank = ranks.find(rank => rank.aa.id === item.id);
  const currentRanks = rank?.ranks ?? [];
  const rankStr = currentRanks.map(r => r.rank).join(', ');

  const structures = item.structures ? (item.structures as AbstractAuthorStructure[]) : [];

  let name = item.firstName + ' ' + item.lastName;

  const isRegistered = isObject(item.person) && showLink;

  if (isRegistered) {
    const person = item.person as Person;
    name = person.firstName + ' ' + person.lastName;
  }
  if (showCivilite && item.civilite) {
    name = t(item.civilite) + ' ' + name;
  }

  const onClick = () => {
    if (isRegistered) {
      const person = item.person as Person;
      navigate(`/sheet/person/${person.id}/main`);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexWrap="nowrap"
      sx={{
        mr: 1,
        mb: 0.8,
      }}
    >
      <Box display="flex" alignItems="center">
        {showIcon && (
          <Box mr={1}>
            <PersonIcon />
          </Box>
        )}
        {showLinkToPerson && (
          <Box mr={1}>
            {item.person ? (
              <UnLinkAuthorBtn author={item} />
            ) : (
              <LinkAuthorToPersonBtn author={item} />
            )}
          </Box>
        )}
      </Box>
      <Box>
        <Box
          onClick={onClick}
          sx={{
            fontWeight: item.speaker ? 'bold' : undefined,
            cursor: isRegistered ? 'pointer' : undefined,
            color: isRegistered ? 'primary.main' : undefined,
          }}
        >
          {name}
          {showRank && nbDistincRanks > 1 && <sup>&nbsp;{rankStr}&nbsp;</sup>}
          {suffix}
        </Box>
        {showStructure && (
          <Box fontSize="small">
            {structures.map(structure => (
              <Box key={structure.id}>{structure.name}</Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AbstractAuthorItem;
