import Box from '@mui/material/Box';
import PersonAbstracts from 'modules/sheet/person/participations/PersonAbstracts';
import { useGetPerson } from 'modules/sheet/sheetHook';

const ParticipationsTab = () => {
  const { data } = useGetPerson();

  return <Box>{data && <PersonAbstracts person={data} />}</Box>;
};

export default ParticipationsTab;
