import StructureMainTab from 'modules/sheet/structure/StructureMainTab';
import StructurePresentationTab from 'modules/sheet/structure/StructurePresentationTab';
import StructureSheet from 'modules/sheet/structure/StructureSheet';
import StructureEmployeesTab from 'modules/sheet/structure/jobs/StructureEmployeesTab';
import StructurePublicationsTab from 'modules/sheet/structure/publications/StructurePublicationsTab';
import StructureTeamsTab from 'modules/sheet/structure/teams/StructureTeamsTab';
import { Outlet, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: 'structure/:id',
    element: (
      <StructureSheet>
        <Outlet />
      </StructureSheet>
    ),
    children: [
      {
        path: 'main',
        element: <StructureMainTab />,
      },
      {
        path: 'presentation',
        element: <StructurePresentationTab />,
      },
      {
        path: 'teams',
        element: <StructureTeamsTab />,
      },
      {
        path: 'employes',
        element: <StructureEmployeesTab />,
      },
      {
        path: 'publications',
        element: <StructurePublicationsTab />,
      },
    ],
  },
];

export default routes;
