import Point from '@arcgis/core/geometry/Point';
import { useUpdatePersonMutation } from 'services/endpoints/PersonEndpoints';
import ShowAndEditGeom from 'shared/components/map/ShowAndEditGeom';

import { Person } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  person: Person;
  className?: string;
};

const PersonEditGeom = ({ person, className }: Props) => {
  const dispatch = useAppDispatch();
  const [updatePerson] = useUpdatePersonMutation();

  const onSaveGeom = async (point: Point) => {
    if (person?.id) {
      updatePerson({
        id: person.id,
        item: {
          geom: {
            type: 'Point',
            coordinates: [point.longitude, point.latitude],
          },
        },
      }).unwrap();
      dispatch(closeAllDrawers());
    }
  };

  return <ShowAndEditGeom point={person.geom} onSave={onSaveGeom} className={className} />;
};

export default PersonEditGeom;
