import CloudIcon from '@mui/icons-material/Cloud';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button/Button';
import Menu from '@mui/material/Menu/Menu';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import HeaderMenuItem from 'shared/components/header/HeaderMenuItem';

const directusUrl = import.meta.env.VITE_DIRECTUS_URL;

const AdminHeaderBtn = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const match = useMatch('/admin/*');

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClick = (url: string) => () => {
    navigate(url);
    handleCloseMenu();
  };

  const openExternalUrl = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleOpenMenu}
        sx={{ color: match ? '#FFF' : '#aaa', marginLeft: 2 }}
        startIcon={<SettingsIcon />}
      >
        {t('header.admin')}
      </Button>

      <Menu
        sx={{ mt: '2rem' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <HeaderMenuItem
          text={t('header.users')}
          icon={<GroupIcon />}
          onClick={onClick('/admin/users')}
        />
        <HeaderMenuItem
          text={t('header.directus')}
          icon={<CloudIcon />}
          onClick={openExternalUrl(directusUrl)}
        />
      </Menu>
    </>
  );
};

export default AdminHeaderBtn;
