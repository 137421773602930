import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { AbstractAuthorStructure } from 'shared/model/types';

type Props = {
  item: AbstractAuthorStructure;
  variant?: 'outlined' | 'filled' | undefined;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'default' | undefined;
  size?: 'small' | 'medium' | undefined;
  onClick?: (item: AbstractAuthorStructure) => void;
  onDeleted?: (item: AbstractAuthorStructure) => void;
};

const AbstractAuthorStructureChip = ({ item, variant, onDeleted, onClick, color, size }: Props) => {
  const handleDelete = onDeleted
    ? () => {
        onDeleted(item);
      }
    : undefined;

  const handleClick = onClick
    ? () => {
        onClick({
          id: item.structure as number,
          name: item.name,
        });
      }
    : undefined;

  return (
    <Box mr={1} mb={1}>
      <Chip
        variant={variant}
        color={color}
        size={size}
        label={item.name}
        onDelete={handleDelete}
        onClick={handleClick}
      />
    </Box>
  );
};

export default AbstractAuthorStructureChip;
