import { zodResolver } from '@hookform/resolvers/zod';
import isObject from 'lodash/isObject';
import { useTranslation } from 'react-i18next';
import {
  AbstractAuthorFormType,
  AbstractFormType,
  abstractTypes,
} from 'shared/model/def/abstract.def';
import { AbstractAuthor, AbstractAuthorStructure, Thematique } from 'shared/model/types';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useAbstractResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    name: z.string().min(1, requiredMessage),
    type: z.enum(abstractTypes),
    content: z.string().min(1, requiredMessage),
    thematique: z.custom<Thematique>(value => isObject(value), { message: requiredMessage }),
    theme: z.string().min(1, requiredMessage),
    reference: z.string(),
    authors: z.custom<AbstractAuthor[]>(value => Array.isArray(value), {
      message: requiredMessage,
    }),
  }) satisfies ZodType<AbstractFormType>;

  return zodResolver(schema);
};
export default useAbstractResolver;

export const useAbstractAuthorResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');
  const requiredLinkBetweenAuthorAndStructureMessage = t('abstract.authorEmptyStructureMessage');

  const schema = z.object({
    civilite: z.string().min(1, requiredMessage),
    firstName: z.string().min(1, requiredMessage),
    lastName: z.string().min(1, requiredMessage),
    sort: z.number().int(),
    speaker: z.boolean(),
    structures: z.custom<AbstractAuthorStructure[]>(
      value => Array.isArray(value) && value.length > 0,
      {
        message: requiredLinkBetweenAuthorAndStructureMessage,
      },
    ),
  }) satisfies ZodType<AbstractAuthorFormType>;

  return zodResolver(schema);
};
