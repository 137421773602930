import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { SheetContentTab } from 'shared/components/sheet/SheetType';

type Props = {
  matchPath: string;
  tabs: SheetContentTab[];
  children: React.ReactNode;
};

const SheetContent = ({ children, tabs, matchPath }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const match = useMatch(matchPath);

  const tab = match?.params.tab;
  const tabValue = tabs.findIndex(item => item.path === tab) ?? 0;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const path = tabs[newValue].path;
    navigate(path);
  };

  return (
    <Box>
      <Card>
        <CardContent
          sx={{
            paddingTop: 0,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Box borderBottom={1} borderColor="divider"></Box>
          <Box pt={1}>
            <Tabs value={tabValue} onChange={handleChange}>
              {tabs.map(tab => (
                <Tab key={tab.path} label={t(tab.label)} />
              ))}
            </Tabs>
          </Box>
          <Box pt={1}>{children}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SheetContent;
