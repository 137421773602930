import { Autocomplete, Box, Button, DialogActions, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationsQuery } from 'services/endpoints/OrganizationEndpoints';
import { Organization, Tutelle } from 'shared/model/types';

type Props = {
  tutelle: Partial<Tutelle>;
  onCancel: () => void;
  onSubmit: (organization: Organization, code: string) => void;
};

const TutelleFormDialog = ({ tutelle, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetOrganizationsQuery({
    limit: 200,
    sort: 'name',
  });

  const organizations = data ?? [];

  const isNew = !tutelle.id;

  const title = isNew ? t('tutelles.add') : t('tutelles.edit');

  const [organization, setOrganization] = useState<Organization | null>(
    tutelle.organization ? (tutelle.organization as Organization) : null,
  );
  const [code, setCode] = useState<string>(tutelle.code ?? '');

  const onOrganizationChange = (e: SyntheticEvent, newValue: Organization | null) => {
    setOrganization(newValue);
  };

  const onCodeChange = (e: SyntheticEvent) => {
    setCode((e.target as HTMLInputElement).value);
  };

  const handleSubmit = () => {
    if (organization) {
      onSubmit(organization, code);
    }
  };

  const btnLabel = isNew ? t('add') : t('edit');

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers
        sx={{
          width: '300px',
        }}
      >
        <Box mb={2}>
          <Autocomplete
            loading={isLoading}
            getOptionLabel={option => option.name ?? ''}
            options={organizations}
            renderInput={params => <TextField {...params} label={t('tutelles.tutelle')} />}
            onChange={onOrganizationChange}
            value={organization}
          />
        </Box>
        <Box mb={2}>
          <TextField label={t('tutelles.code')} onChange={onCodeChange} value={code ?? ''} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button type="button" onClick={handleSubmit}>
          {btnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TutelleFormDialog;
