import { Box, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useTeamCreationResolver } from 'modules/sheet/team/useTeamResolver';
import { Controller, useForm } from 'react-hook-form';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { TeamFormType, TeamIcon } from 'shared/model/def/team.def';
import { Team } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  team: Partial<Team>;
  disabled?: boolean;
  onSubmit: (team: TeamFormType) => void;
};

const TeamCreationForm = ({ team, disabled = false, onSubmit }: Props) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const resolver = useTeamCreationResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TeamFormType>({
    defaultValues: {
      ...team,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  return (
    <Box>
      <FormTemplate fullWidth titlei18n="team.add" icon={<TeamIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('name')}
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default TeamCreationForm;
