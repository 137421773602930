import { Box } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import AbstractTypesTable from 'modules/sheet/abstract/AbstractTypesTable';
import EditAbstractBtn from 'modules/sheet/abstract/EditAbstractBtn';
import EditableCard from 'shared/components/Card/EditableCard';
import { ThematiqueIcon } from 'shared/model/def/thematique.def';
import { Abstract, Thematique } from 'shared/model/types';

type Props = {
  abstract: Abstract;
};

const AbstractInformations = ({ abstract }: Props) => {
  const { t } = useTranslate();
  const thematique = abstract.thematique as Thematique | undefined;

  return (
    <EditableCard
      title={t('abstract.informations')}
      actions={<EditAbstractBtn hideAuthors abstract={abstract} />}
    >
      <Box mb={2}>Missing event</Box>
      <Box mb={2}>Missing private space</Box>
      {thematique && (
        <Box display="flex" mb={2}>
          <ThematiqueIcon />
          <Box ml={1}>{thematique.name}</Box>
        </Box>
      )}

      <Box mb={2}>
        <Box display="inline" fontWeight="bold">
          {t('abstract.theme')}:
        </Box>
        &nbsp;{abstract.theme}
      </Box>

      <Box>
        <AbstractTypesTable abstract={abstract} />
      </Box>
    </EditableCard>
  );
};

export default AbstractInformations;
