import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TeamMemberFormType, TeamMemberIcon } from 'modules/sheet/team/teamMember/teamMember.def';
import useTeamMemberResolver from 'modules/sheet/team/teamMember/useTeamMemberResolver';

import { useTranslate } from '@tolgee/react';
import { Controller, useForm } from 'react-hook-form';
import MetierAutocomplete from 'shared/components/autocomplete/MetierAutocomplete';
import PersonAutocomplete from 'shared/components/autocomplete/PersonAutocomplete';
import StatutAutocomplete from 'shared/components/autocomplete/StatutAutocomplete';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { Metier, Person, Statut, TeamMember } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  teamMember: Partial<TeamMember>;
  onSubmit: (teamMember: TeamMemberFormType) => void;
  disabled?: boolean;
};

const TeamMemberForm = ({ teamMember, onSubmit, disabled = false }: Props) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const resolver = useTeamMemberResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<TeamMemberFormType>({
    defaultValues: {
      label: teamMember.label ?? '',
      person: teamMember.person,
      metier: teamMember.metier,
      statut: teamMember.statut,
      startDate: teamMember.startDate,
      endDate: teamMember.endDate,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const metierSelected = watch('metier') as Metier | undefined;

  const titleI18n = teamMember.id ? 'members.edit' : 'members.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleI18n} icon={<TeamMemberIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="person"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <PersonAutocomplete
                    value={value as Person | null}
                    onChange={onChange}
                    error={!!errors.person}
                    helperText={errors.person?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="label"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('label')}
                  {...field}
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="metier"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MetierAutocomplete
                    value={value as Metier | null}
                    onChange={onChange}
                    error={!!errors.metier}
                    helperText={errors.metier?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="statut"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StatutAutocomplete
                    value={value as Statut | null}
                    onChange={onChange}
                    error={!!errors.statut}
                    helperText={errors.statut?.message}
                    metier={metierSelected}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={t('startDate')}
                    value={field.value ? dayjs(field.value) : null}
                    inputRef={field.ref}
                    onChange={date => {
                      field.onChange(date);
                    }}
                    timezone="UTC"
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        required: !!errors.startDate,
                        helperText: errors.startDate?.message,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={t('endDate')}
                    value={field.value ? dayjs(field.value) : null}
                    inputRef={field.ref}
                    onChange={date => {
                      field.onChange(date);
                    }}
                    timezone="UTC"
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        required: !!errors.endDate,
                        helperText: errors.endDate?.message,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default TeamMemberForm;
