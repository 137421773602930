import Point from '@arcgis/core/geometry/Point';
import { useUpdateStructureMutation } from 'services/endpoints/StructureEndpoints';
import ShowAndEditGeom from 'shared/components/map/ShowAndEditGeom';
import { getStructureAdress } from 'shared/model/def/structure.def';

import { Structure } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structure: Structure;
  className?: string;
};

const StructureEditGeom = ({ structure, className }: Props) => {
  const dispatch = useAppDispatch();
  const [updateStructure] = useUpdateStructureMutation();

  const onSaveGeom = async (point: Point) => {
    updateStructure({
      id: structure.id,
      item: {
        geom: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      },
    }).unwrap();
    dispatch(closeAllDrawers());
  };

  const address = getStructureAdress(structure);

  return (
    <ShowAndEditGeom
      point={structure.geom}
      defaultAddress={address}
      onSave={onSaveGeom}
      className={className}
    />
  );
};

export default StructureEditGeom;
