import { Button, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { usePersonCreationResolver } from 'modules/sheet/person/usePersonResolver';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateAbstractAuthorMutation } from 'services/endpoints/AbstractEndpoints';
import { useCreatePersonMutation } from 'services/endpoints/PersonEndpoints';
import CiviliteSelect from 'shared/components/select/CiviliteSelect';
import { CiviliteType, PersonFormType } from 'shared/model/def/person.def';
import { AbstractAuthor } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  author: AbstractAuthor;
};

const LinkAuthorToNewPerson = ({ author }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const [createPerson, { isLoading: isCreating }] = useCreatePersonMutation();
  const [updateAuthor, { isLoading: isUpdatingAuthor }] = useUpdateAbstractAuthorMutation();

  const resolver = usePersonCreationResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonFormType>({
    defaultValues: {
      civilite: author.civilite,
      firstName: author.firstName,
      lastName: author.lastName,
    },
    resolver,
  });

  if (!showForm) {
    const onClick = () => {
      setShowForm(true);
    };

    return (
      <Box textAlign="center">
        <Button type="button" onClick={onClick}>
          {t('person.add')}
        </Button>
      </Box>
    );
  }

  const onAdd = async (data: PersonFormType) => {
    const createdPerson = await createPerson({
      item: data,
    }).unwrap();

    await updateAuthor({
      id: author.id,
      item: {
        person: createdPerson.id,
      },
    });

    dispatch(closeAllDrawers());
  };

  const disabled = isCreating || isUpdatingAuthor;

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box mt={2}>
        <Controller
          name="civilite"
          control={control}
          rules={{ required: t('form.errors.required') }}
          render={({ field: { onChange, value } }) => (
            <CiviliteSelect
              value={value as CiviliteType}
              onChange={onChange}
              error={!!errors.civilite}
              helperText={errors.civilite?.message}
              disabled={disabled}
            />
          )}
        />
      </Box>
      <Box mt={2}>
        <Controller
          name="firstName"
          control={control}
          disabled={disabled}
          rules={{ required: t('form.errors.required') }}
          render={({ field }) => (
            <TextField
              label={t('firstName')}
              {...field}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />
      </Box>
      <Box mt={2}>
        <Controller
          name="lastName"
          control={control}
          rules={{ required: t('form.errors.required') }}
          disabled={disabled}
          render={({ field }) => (
            <TextField
              label={t('lastName')}
              {...field}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          )}
        />
      </Box>
      <Box mt={2} textAlign="center">
        <Button type="button" onClick={handleSubmit(onAdd)} disabled={disabled}>
          {t('abstract.addAndLinkPerson')}
        </Button>
      </Box>
    </Paper>
  );
};

export default LinkAuthorToNewPerson;
