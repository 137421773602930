import { Box } from '@mui/material';
import { AuthorStructureRank } from 'modules/sheet/abstract/AbstractHook';

type Props = {
  item: AuthorStructureRank;
  showRank?: boolean;
  suffix?: string;
};

const AbstractStructureItem = ({ item, showRank = false, suffix = '' }: Props) => {
  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        marginRight: 1,
      }}
    >
      {showRank && <sup>&nbsp;{item.rank}&nbsp;</sup>}
      {item.name}
      {suffix}
    </Box>
  );
};

export default AbstractStructureItem;
