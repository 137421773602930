import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getStructureAdress, StructureIcon } from 'shared/model/def/structure.def';
import { Job, Metier, Statut, Structure } from 'shared/model/types';
import { DATE_FORMAT } from 'shared/utils/date-utils';

type Props = {
  job: Job;
};

const PersonJobStep = ({ job }: Props) => {
  const { t } = useTranslation();
  const structure = job.structure as Structure;
  const structureName = structure.name;

  const address = getStructureAdress(structure);

  const startDate = job.startDate ? dayjs(job.startDate).format(DATE_FORMAT) : t('noDate');
  const endDate = job.endDate ? dayjs(job.endDate).format(DATE_FORMAT) : t('noDate');

  const metier = job.metier as Metier | undefined;
  const statut = job.statut as Statut | undefined;

  const metierLabel = metier?.name || '';
  const statutLabel = statut?.name || '';

  return (
    <Step active>
      <StepLabel icon={<StructureIcon />}>{structureName}</StepLabel>
      <StepContent>
        <Box fontSize="0.8rem">
          <Box>{job.label}</Box>
          <Box>{address}</Box>
          <Box>
            {metierLabel} - {statutLabel}
          </Box>
          <Box color="grey">
            <Box>
              {t('startDate')}: {startDate}
            </Box>
            <Box>
              {t('endDate')}: {endDate}
            </Box>
          </Box>
        </Box>
      </StepContent>
    </Step>
  );
};

export default PersonJobStep;
