import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslate } from '@tolgee/react';

type Props = {
  cancelUrl?: string;
  onCancel?: () => void;
  disabled?: boolean;
  cancelKey?: string;
  saveKey?: string;
  formId?: string;
};

const FormButtons = ({
  cancelUrl,
  disabled = false,
  cancelKey = 'cancel',
  saveKey = 'save',
  formId,
  onCancel,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Box mt={4} textAlign="center">
      <Button
        type="button"
        variant="outlined"
        sx={{ mr: 2 }}
        href={cancelUrl}
        onClick={onCancel}
        disabled={disabled}
      >
        <CancelIcon sx={{ marginRight: 1 }} />
        {t(cancelKey)}
      </Button>
      <Button type="submit" disabled={disabled} form={formId}>
        <SaveIcon sx={{ marginRight: 1 }} />
        {t(saveKey)}
      </Button>
    </Box>
  );
};

export default FormButtons;
