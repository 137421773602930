import EditIcon from '@mui/icons-material/Edit';
import PublicationForm from 'modules/sheet/structure/publications/PublicationForm';
import { useTranslation } from 'react-i18next';
import { useUpdatePublicationMutation } from 'services/endpoints/PublicationEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { PublicationFormType } from 'shared/model/def/Publication.def';
import { Publication } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  publication: Publication;
};

const EditStructurePublicationBtn = ({ publication }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [updatePublication, { isLoading }] = useUpdatePublicationMutation();

  const onSubmit = async (newPub: PublicationFormType) => {
    await updatePublication({
      id: publication.id,
      item: newPub,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton type="button" text={t('edit')} icon={<EditIcon />}>
      <PublicationForm publication={publication} onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default EditStructurePublicationBtn;
