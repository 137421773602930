import { useDeleteAbstractMutation } from 'services/endpoints/AbstractEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';

type Props = {
  ids: number[];
  onDeleted?: () => void;
};

const DeleteAbstractsBtn = ({ ids, onDeleted }: Props) => {
  const [deleteItem] = useDeleteAbstractMutation();

  const onConfirm = async () => {
    await deleteItem(ids).unwrap();
    if (onDeleted) {
      onDeleted();
    }
  };

  return <ConfirmDelete onlyIcon onConfirm={onConfirm} />;
};

export default DeleteAbstractsBtn;
