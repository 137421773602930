import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import SearchResultPerson from 'modules/search/results/SearchResultPerson';
import LinkAuthorToNewPerson from 'modules/sheet/abstract/authors/LinkAuthorToNewPerson';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateAbstractAuthorMutation } from 'services/endpoints/AbstractEndpoints';
import PersonAutocomplete from 'shared/components/autocomplete/PersonAutocomplete';
import PageTitle from 'shared/components/PageTitle';
import { AbstractAuthor, Person } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  author: AbstractAuthor;
};

const LinkAuthorToPersonForm = ({ author }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedPerson, setSelectedPerson] = useState(author.person as Person | null);

  const [updateAuthor, { isLoading: isUpdating }] = useUpdateAbstractAuthorMutation();

  const name = author.firstName + ' ' + author.lastName;

  const onPersonChange = (person: Person | null) => {
    setSelectedPerson(person);
  };

  const onConfirm = async () => {
    if (selectedPerson?.id) {
      await updateAuthor({
        id: author.id,
        item: {
          person: selectedPerson.id,
        },
      }).unwrap();

      dispatch(closeAllDrawers());
    }
  };

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const disabled = isUpdating;

  return (
    <Box p={2}>
      <Box mb={2}>
        <PageTitle
          i18n={t('abstract.linkToPersonTitle', {
            name,
          })}
        />
      </Box>
      <Box mb={2}>
        <PersonAutocomplete
          defaultInputValue={name}
          value={selectedPerson}
          onChange={onPersonChange}
          disabled={disabled}
          fields={['*']}
        />
      </Box>
      <Box mb={2}>
        <Paper sx={{ p: 2 }} elevation={3}>
          {selectedPerson ? (
            <Box>
              <Box mb={2}>{t('searchDirectoryResult')}</Box>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Box mb={1} fontWeight="bold">
                    {selectedPerson.civilite && (
                      <Box display="inline" mr={1}>
                        {t(selectedPerson.civilite)}
                      </Box>
                    )}
                    {selectedPerson.firstName} {selectedPerson.lastName}
                  </Box>
                  <SearchResultPerson person={selectedPerson} noDescription />
                </Box>
                <Box>
                  <Button type="button" onClick={onConfirm} disabled={!selectedPerson || disabled}>
                    {t('linkTo')}
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box textAlign="center">{t('abstract.linkToPersonNoResult')}</Box>
          )}
        </Paper>
      </Box>

      <Box mb={2}>
        <LinkAuthorToNewPerson author={author} />
      </Box>

      <Box textAlign="center" mt={4}>
        <Button
          type="button"
          variant="outlined"
          onClick={onCancel}
          disabled={disabled}
          sx={{ mr: 2 }}
        >
          {t('cancel')}
        </Button>
      </Box>
    </Box>
  );
};

export default LinkAuthorToPersonForm;
