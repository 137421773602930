import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid2,
  Paper,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTranslate } from '@tolgee/react';
import AbstractAuthorStructureChip from 'modules/sheet/abstract/authors/AbstractAuthorStructureChip';
import { useAbstractAuthorResolver } from 'modules/sheet/abstract/form/useAbstractResolver';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PersonAutocomplete from 'shared/components/autocomplete/PersonAutocomplete';
import StructureAutocomplete from 'shared/components/autocomplete/StructureAutocomplete';
import CiviliteSelect from 'shared/components/select/CiviliteSelect';
import { AbstractAuthorFormType } from 'shared/model/def/abstract.def';
import { CiviliteType } from 'shared/model/def/person.def';
import { AbstractAuthor, AbstractAuthorStructure, Person, Structure } from 'shared/model/types';

type Props = {
  author: Partial<AbstractAuthor>;
  existingStructures?: AbstractAuthorStructure[];
  onCancel: () => void;
  onConfirm: (author: Partial<AbstractAuthor>) => void;
};

const AuthorFormDialog = ({ author, existingStructures, onCancel, onConfirm }: Props) => {
  const { t } = useTranslate();
  const isNew = !author.id;

  const [selectedPerson, setSelectedPerson] = useState(author.person as Person | null);

  const title = isNew ? t('abstract.addAuthor') : t('abstract.editAuhtor');

  const resolver = useAbstractAuthorResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm<AbstractAuthorFormType>({
    defaultValues: {
      ...author,
    },
    resolver,
  });

  const onPersonChange = (person: Person | null) => {
    setSelectedPerson(person);
    if (person?.civilite) {
      setValue('civilite', person.civilite);
    }
    if (person?.firstName) {
      setValue('firstName', person.firstName);
    }
    if (person?.lastName) {
      setValue('lastName', person.lastName);
    }
    if (person?.id) {
      setValue('person', person.id);
    }
  };

  const onStructureAdded = (structure: Structure | AbstractAuthorStructure | null) => {
    if (structure) {
      const structures = getValues('structures');

      const some = structures.some(s => s.name === structure.name);
      if (!some && structure.name) {
        const newAbstractStructure: Omit<AbstractAuthorStructure, 'id'> = {
          name: structure.name,
          structure: structure.id,
        };

        const newStructures = [...structures, newAbstractStructure];
        setValue('structures', newStructures);
      }
    }
  };

  const onStructureDeleted = (structure: AbstractAuthorStructure) => {
    const structures = getValues('structures');
    const newStructures = structures.filter(s => s.name !== structure.name);
    setValue('structures', newStructures);
  };

  const onSubmit = (data: AbstractAuthorFormType) => {
    const newAuthor: Partial<AbstractAuthor> = {
      ...data,
    };
    onConfirm(newAuthor);
  };

  const structures = watch('structures');

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Grid2 container spacing={2}>
          <Grid2
            size={{
              xs: 12,
              sm: 6,
              md: 4,
            }}
          >
            <Box mb={2}>
              <PersonAutocomplete
                value={selectedPerson}
                onChange={onPersonChange}
                helperText={t('abstract.authorAutocompleteHelp')}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="civilite"
                control={control}
                rules={{ required: t('form.errors.required') }}
                render={({ field: { onChange, value } }) => (
                  <CiviliteSelect
                    value={value as CiviliteType}
                    onChange={onChange}
                    error={!!errors.civilite}
                    helperText={errors.civilite?.message}
                  />
                )}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: t('form.errors.required') }}
                render={({ field }) => (
                  <TextField
                    label={t('firstName')}
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: t('form.errors.required') }}
                render={({ field }) => (
                  <TextField
                    label={t('lastName')}
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Box>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 6,
              md: 8,
            }}
          >
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box mb={1} fontWeight="bold">
                {t('abstract.linkAuthorAndStructure')}
              </Box>
              {existingStructures && existingStructures.length > 0 && (
                <Box mb={2}>
                  <Box mb={1} fontSize="smaller">
                    {t('abstract.structureAlreadyInAbstract')}
                  </Box>
                  <Box display="flex" flexWrap="wrap">
                    {existingStructures?.map(es => (
                      <AbstractAuthorStructureChip
                        key={es.name}
                        variant="outlined"
                        item={es}
                        onClick={onStructureAdded}
                      />
                    ))}
                  </Box>
                </Box>
              )}
              <Box mb={2}>
                <StructureAutocomplete
                  value={null}
                  onChange={onStructureAdded}
                  helperText={t('abstract.structureAutocompleteHelp')}
                />
                <Box mt={2}>
                  <TextField label={t('abstract.authorStructureLabel')} />
                </Box>
              </Box>
              <Box mb={2} display="flex" flexWrap="wrap">
                {structures.map(s => (
                  <AbstractAuthorStructureChip
                    key={s.name}
                    color="primary"
                    item={s}
                    onDeleted={onStructureDeleted}
                  />
                ))}
              </Box>
              <FormHelperText error>{errors.structures?.message}</FormHelperText>
            </Paper>
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button type="button" onClick={handleSubmit(onSubmit)}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthorFormDialog;
