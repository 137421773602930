import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslate } from '@tolgee/react';
import { z } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useRequestResetPwdResolver = () => {
  const { t } = useTranslate();

  const emailMessage = t('form.errors.emailPattern');

  const schema = z.object({
    email: z.string().email({ message: emailMessage }),
  });

  return zodResolver(schema);
};

export default useRequestResetPwdResolver;
