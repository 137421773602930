import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Publication } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['publication', 'publications'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getPublications: builder.query<Publication[], RequestQuery<Publication>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Publication[]>(readItems('Publication', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['publications'],
      }),
      getPublication: builder.query<Publication, { id: string; q?: RequestQuery<Publication> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Publication>(readItem('Publication', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['publication'],
      }),
      createPublication: builder.mutation<
        Publication,
        {
          item: Partial<Publication>;
          query?: MutationQuery<Publication>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Publication>(
              createItem('Publication', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['publication', 'publications'],
      }),
      updatePublication: builder.mutation<
        Publication,
        {
          id: string | number;
          item: Partial<Publication>;
          query?: MutationQuery<Publication>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Publication>(
              updateItem('Publication', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['publication', 'publications'],
      }),
      deletePublication: builder.mutation<void, Publication[]>({
        queryFn: async publication => {
          try {
            const ids = publication.map(item => item.id);
            await directus.request(deleteItems('Publication', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['publication', 'publications'],
      }),
    }),
  });

export const {
  useGetPublicationsQuery,
  useGetPublicationQuery,
  useCreatePublicationMutation,
  useUpdatePublicationMutation,
  useDeletePublicationMutation,
} = extendedApi;
