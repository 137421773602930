import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button/Button';
import Menu from '@mui/material/Menu/Menu';
import { useTranslate } from '@tolgee/react';
import CreatePersonBtn from 'modules/sheet/person/createPersonBtn';
import CreateStructureBtn from 'modules/sheet/structure/CreateStructureBtn';
import CreateTeamBtn from 'modules/sheet/team/createTeamBtn';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/redux/redux-utils';

const AddHeaderMenu = () => {
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const rightDrawer = useAppSelector(state => state.drawer.right);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (rightDrawer) {
      handleCloseMenu();
    }
  }, [rightDrawer]);

  return (
    <>
      <Button
        variant="text"
        onClick={handleOpenMenu}
        sx={{
          color: '#aaa',
          marginLeft: 2,
        }}
        startIcon={<AddIcon />}
      >
        {t('add')}
      </Button>

      <Menu
        sx={{ mt: '2rem' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <CreatePersonBtn />
        <CreateTeamBtn />
        <CreateStructureBtn />
      </Menu>
    </>
  );
};

export default AddHeaderMenu;
