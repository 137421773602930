import { Avatar } from '@mui/material';
import { blue, blueGrey, deepOrange, teal, yellow } from '@mui/material/colors';
import { AbstractIcon } from 'shared/model/def/abstract.def';
import { PersonIcon } from 'shared/model/def/person.def';
import { StructureIcon } from 'shared/model/def/structure.def';
import { TeamIcon } from 'shared/model/def/team.def';

type Props = {
  model: 'person' | 'team' | 'structure' | 'abstract' | 'project';
  asAvatar?: boolean;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
};

const ModelIcon = ({ model, fontSize, asAvatar = false }: Props) => {
  let width = 24;
  let height = 24;

  if (fontSize === 'large') {
    width = 48;
    height = 48;
  } else if (fontSize === 'medium') {
    width = 36;
    height = 36;
  }

  if (model === 'person') {
    const color = blue[900];
    if (asAvatar) {
      return (
        <Avatar
          sx={{
            bgcolor: color,
            width,
            height,
          }}
        >
          <PersonIcon fontSize={fontSize} />
        </Avatar>
      );
    }

    return (
      <PersonIcon
        fontSize={fontSize}
        sx={{
          color,
        }}
      />
    );
  } else if (model === 'team') {
    const color = teal[800];
    if (asAvatar) {
      return (
        <Avatar
          sx={{
            bgcolor: color,
            width,
            height,
          }}
        >
          <TeamIcon fontSize={fontSize} />
        </Avatar>
      );
    }

    return (
      <TeamIcon
        fontSize={fontSize}
        sx={{
          color,
        }}
      />
    );
  } else if (model === 'structure') {
    const color = deepOrange[900];
    if (asAvatar) {
      return (
        <Avatar
          sx={{
            bgcolor: color,
            width,
            height,
          }}
        >
          <StructureIcon fontSize={fontSize} />
        </Avatar>
      );
    }
    return (
      <StructureIcon
        fontSize={fontSize}
        sx={{
          color,
        }}
      />
    );
  } else if (model === 'project') {
    const color = yellow[800];
    if (asAvatar) {
      return (
        <Avatar
          sx={{
            bgcolor: color,
            width,
            height,
          }}
        >
          <TeamIcon fontSize={fontSize} />
        </Avatar>
      );
    }

    return (
      <TeamIcon
        fontSize={fontSize}
        sx={{
          color,
        }}
      />
    );
  } else if (model === 'abstract') {
    const color = blueGrey[800];
    if (asAvatar) {
      return (
        <Avatar
          sx={{
            bgcolor: color,
            width,
            height,
          }}
        >
          <AbstractIcon fontSize={fontSize} />
        </Avatar>
      );
    }

    return (
      <AbstractIcon
        fontSize={fontSize}
        sx={{
          color,
        }}
      />
    );
  }

  return null;
};

export default ModelIcon;
