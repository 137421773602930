import { useTranslate } from '@tolgee/react';
import TeamCreationForm from 'modules/sheet/team/TeamCreationForm';
import { useNavigate } from 'react-router-dom';
import { useCreateTeamMutation } from 'services/endpoints/TeamEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import ModelIcon from 'shared/model/def/ModelIcon';
import { TeamFormType } from 'shared/model/def/team.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const CreateTeamBtn = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [createTeam, { isLoading }] = useCreateTeamMutation();

  const onSubmit = async (newTeam: TeamFormType) => {
    const savedTeam = await createTeam({
      item: newTeam,
    }).unwrap();

    navigate(`/sheet/team/${savedTeam.id}/main`);

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton type="menuItem" text={t('team.team')} icon={<ModelIcon model="team" />}>
      <TeamCreationForm team={{}} onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default CreateTeamBtn;
