import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslate } from '@tolgee/react';
import { z } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useInviteUSerResolver = () => {
  const { t } = useTranslate();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    email: z.string().min(1, { message: requiredMessage }),
    role: z.string().min(1, { message: requiredMessage }),
  });

  return zodResolver(schema);
};
export default useInviteUSerResolver;
