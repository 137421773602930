import { Box } from '@mui/material';
import DeleteAbstractsBtn from 'modules/sheet/abstract/DeleteAbstractsBtn';
import EditAbstractBtn from 'modules/sheet/abstract/EditAbstractBtn';
import { useGetAbstract } from 'modules/sheet/sheetHook';

import { useNavigate } from 'react-router-dom';
import IconValue from 'shared/components/IconValue';
import Loader from 'shared/components/Loader';
import SheetContent from 'shared/components/sheet/SheetContent';
import SheetTitle from 'shared/components/sheet/SheetTitle';
import { useSaveHistory } from 'shared/hooks/historyHook';
import { AbstractIcon } from 'shared/model/def/abstract.def';
import ModelName from 'shared/model/def/ModelName';

const tabs = [
  {
    label: 'sheetAbstract.tabMain',
    path: 'main',
  },
  {
    label: 'sheetAbstract.tabContent',
    path: 'content',
  },
  {
    label: 'sheetAbstract.tabPdf',
    path: 'pdf',
  },
];

type Props = {
  children: React.ReactNode;
};

const AbstractSheet = ({ children }: Props) => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetAbstract();

  useSaveHistory('abstract', data?.id, data?.name ?? undefined);

  if (isLoading) {
    return <Loader />;
  }
  if (!data) {
    return null;
  }

  const onDeleted = () => {
    navigate('/search');
  };

  return (
    <Box px={1}>
      <SheetTitle
        actions={
          <>
            <EditAbstractBtn abstract={data} />
            <DeleteAbstractsBtn ids={[data.id]} onDeleted={onDeleted} />
          </>
        }
      >
        <IconValue icon={<AbstractIcon fontSize="large" />}>
          <ModelName model={data} type="abstract" fontSize={24} />
        </IconValue>
      </SheetTitle>
      <SheetContent tabs={tabs} matchPath="/sheet/abstract/:id/:tab">
        {children}
      </SheetContent>
    </Box>
  );
};

export default AbstractSheet;
