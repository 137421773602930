import { Box, Grid2 as Grid } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import PersonEditGeom from 'modules/sheet/person/PersonEditGeom';
import PersonInfo from 'modules/sheet/person/PersonInfo';
import PersonScientificAttachmentsList from 'modules/sheet/person/PersonScientificAttachmentsList';
import { useGetPerson } from 'modules/sheet/sheetHook';
import { useUpdatePersonMutation } from 'services/endpoints/PersonEndpoints';
import EditableCartTextEditor from 'shared/components/Card/EditableCartTextEditor';

const PersonMainTab = () => {
  const { t } = useTranslate();
  const { data } = useGetPerson();

  const [updatePerson, { isLoading }] = useUpdatePersonMutation();

  if (!data) {
    return null;
  }

  const onDescriptionChange = async (content: string) => {
    return updatePerson({
      id: data.id,
      item: {
        description: content,
      },
    });
  };

  const onPrivateDescChange = async (content: string) => {
    return updatePerson({
      id: data.id,
      item: {
        privateDescription: content,
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <Box mb={2}>
            <PersonInfo person={data} />
          </Box>
          <Box mb={2}>
            <PersonEditGeom person={data} />
          </Box>
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <Box mb={2}>
            <EditableCartTextEditor
              title={t('description')}
              content={data.description}
              onValid={onDescriptionChange}
              disabled={isLoading}
            />
          </Box>
          <Box mb={2}>
            <EditableCartTextEditor
              title={t('privateDescription')}
              content={data.privateDescription}
              onValid={onPrivateDescChange}
              disabled={isLoading}
            />
          </Box>
          <PersonScientificAttachmentsList person={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonMainTab;
