import { useTranslate } from '@tolgee/react';
import TeamCreationForm from 'modules/sheet/team/TeamCreationForm';
import { useCreateTeamMutation } from 'services/endpoints/TeamEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { TeamFormType, TeamIcon } from 'shared/model/def/team.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structureId: number;
};

const CreateStructureTeamBtn = ({ structureId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();

  const [createTeam, { isLoading }] = useCreateTeamMutation();

  const onSubmit = async (newTeam: TeamFormType) => {
    newTeam.structure = structureId;
    await createTeam({
      item: newTeam,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton type="txtButton" text={t('create')} icon={<TeamIcon />}>
      <TeamCreationForm team={{}} onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default CreateStructureTeamBtn;
