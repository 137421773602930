import InfoIcon from '@mui/icons-material/Info';
import { t } from 'i18next';
import SearchResultStructure from 'modules/search/results/SearchResultStructure';
import StructureForm from 'modules/sheet/structure/StructureForm';
import { useUpdateStructureMutation } from 'services/endpoints/StructureEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import EditableCard from 'shared/components/Card/EditableCard';
import IconValue from 'shared/components/IconValue';
import { StructureFormType } from 'shared/model/def/structure.def';
import { Structure } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structure: Structure;
};

const StructureInfo = ({ structure }: Props) => {
  const dispatch = useAppDispatch();

  const [updateItem, { isLoading }] = useUpdateStructureMutation();

  const onSubmit = async (newItem: StructureFormType) => {
    await updateItem({
      id: structure.id,
      item: newItem,
    }).unwrap();

    dispatch(closeAllDrawers());
  };

  return (
    <EditableCard
      title={<IconValue icon={<InfoIcon />}>{t('contact')}</IconValue>}
      actions={
        <OpenDrawerButton>
          <StructureForm structure={structure} onSubmit={onSubmit} disabled={isLoading} />
        </OpenDrawerButton>
      }
    >
      <SearchResultStructure structure={structure} noDescription />
    </EditableCard>
  );
};

export default StructureInfo;
