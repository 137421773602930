import { useMemo } from 'react';
import { Abstract, AbstractAuthor, AbstractAuthorStructure } from 'shared/model/types';

export const useGetAbstractAuthors = (data?: Abstract) => {
  return useMemo(() => {
    const authors = data?.authors ? [...data.authors] : [];
    authors.sort((a, b) => a.order - b.order);
    return authors as AbstractAuthor[];
  }, [data?.authors]);
};

export interface AuthorRank {
  aa: AbstractAuthor;
  ranks: AuthorStructureRank[];
}

export interface AuthorStructureRank {
  id: number;
  name: string;
  rank: number;
}

export const useGetAbstractStructuresRanks = (data?: Abstract) => {
  const authors = useGetAbstractAuthors(data);

  return useMemo(() => {
    const structureRanks = authors.reduce((acc: AuthorStructureRank[], author) => {
      const structures = author.structures;

      structures.forEach(structure => {
        const id = structure.structure ?? structure.name;
        const found = acc.find(r => r.id === id);
        if (!found) {
          acc.push({
            id,
            name: structure.name,
            rank: acc.length + 1,
          });
        }
      });
      return acc;
    }, []);
    return structureRanks;
  }, [authors]);
};

export const useGetAuthorsRanks = (data?: Abstract) => {
  const authors = useGetAbstractAuthors(data);
  const structureRanks = useGetAbstractStructuresRanks(data);

  return useMemo(() => {
    return authors.reduce((acc: AuthorRank[], author) => {
      const found = acc.find(r => r.aa.id === author.id);
      if (!found) {
        const authStructures = author.structures as AbstractAuthorStructure[];
        const ranks = structureRanks.filter(sr =>
          authStructures.find(s => s.structure === sr.id || s.name === sr.name),
        );
        acc.push({
          aa: author,
          ranks,
        });
      }
      return acc;
    }, []);
  }, [structureRanks]);
};
