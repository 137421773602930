import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AuthorFormDialog from 'modules/sheet/abstract/authors/AuthorFormDialog';
import { useState } from 'react';
import { AbstractAuthor, AbstractAuthorStructure } from 'shared/model/types';

type Props = {
  item: Partial<AbstractAuthor>;
  onEdit: (author: Partial<AbstractAuthor>) => void;
  existingStructures?: AbstractAuthorStructure[];
};

const EditAuthorBtn = ({ item, onEdit, existingStructures }: Props) => {
  const [showDialog, setShowDialog] = useState(false);

  const onClick = () => {
    setShowDialog(true);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const onConfirm = (author: Partial<AbstractAuthor>) => {
    setShowDialog(false);
    onEdit(author);
  };

  return (
    <>
      <IconButton onClick={onClick} size="small" color="primary">
        <EditIcon />
      </IconButton>
      {showDialog && (
        <AuthorFormDialog
          author={{ ...item }}
          existingStructures={existingStructures}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default EditAuthorBtn;
