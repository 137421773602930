import Point from '@arcgis/core/geometry/Point';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import clsx from 'clsx';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import EditableCard from 'shared/components/Card/EditableCard';
import IconValue from 'shared/components/IconValue';
import EditGeomInMap from 'shared/components/map/EditGeomInMap';
import { Point as DatabasePoint } from 'shared/model/geometry.model';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
import Map from './Map';
const defaultPosition = import.meta.env.VITE_DEFAULT_POSITION;

const defaultLatitude = defaultPosition.split(',')[0];
const defaultLongitude = defaultPosition.split(',')[1];

type Props = {
  point?: DatabasePoint | null;
  defaultAddress?: string;
  className?: string;
  zoom?: number;
  onSave: (point: Point) => void;
};

const ShowAndEditGeom = ({ point, defaultAddress, className, zoom, onSave }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate();

  const arcGisPoint = new Point({
    latitude: point?.coordinates[1] ?? defaultLatitude,
    longitude: point?.coordinates[0] ?? defaultLongitude,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const alignItems = point ? 'flex-start' : 'center';

  return (
    <EditableCard
      title={<IconValue icon={<LocationOnIcon />}>{t('localisation')}</IconValue>}
      actions={
        <OpenDrawerButton>
          <EditGeomInMap
            className={clsx('map-in-drawer', className)}
            defaultAddress={defaultAddress}
            point={arcGisPoint}
            zoom={zoom}
            onCancel={onCancel}
            onSave={onSave}
          />
        </OpenDrawerButton>
      }
    >
      <Box display="flex" alignItems={alignItems} justifyContent="space-between">
        <Map className={className} point={point ? arcGisPoint : undefined} zoom={zoom} />
      </Box>
    </EditableCard>
  );
};

export default ShowAndEditGeom;
