import { DefaultParamType, TFnType, TranslationKey } from '@tolgee/react';
import PasswordValidator from 'password-validator';

export const validatePassword = (
  password: string,
  t: TFnType<DefaultParamType, string, TranslationKey>,
) => {
  const min = 8;
  const max = 100;
  const upper = 1;
  const lower = 2;
  const digit = 2;

  const minMessage = t('passwordStrength.min', { nb: min });
  const maxMessage = t('passwordStrength.max', { nb: max });
  const upperMessage = t('passwordStrength.uppercase', { nb: upper });
  const lowerMessage = t('passwordStrength.lowercase', { nb: lower });
  const digitMessage = t('passwordStrength.digit', { nb: digit });
  const spaceMessage = t('passwordStrength.space');

  const schema = new PasswordValidator()
    .is()
    .min(min, minMessage)
    .is()
    .max(100, maxMessage)
    .has()
    .uppercase(upper, upperMessage)
    .has()
    .lowercase(lower, lowerMessage)
    .has()
    .digits(digit, digitMessage)
    .has()
    .not()
    .spaces(0, spaceMessage);

  return schema.validate(password, {
    list: true,
    details: true,
  }) as any[];
};
