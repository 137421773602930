import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AbstractEditableAuthors from 'modules/sheet/abstract/authors/AbstractEditableAuthors';
import useAbstractResolver from 'modules/sheet/abstract/form/useAbstractResolver';
import Scrollbars from 'react-custom-scrollbars';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ThematiqueAutocomplete from 'shared/components/autocomplete/ThematiqueAutocomplete';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { AbstractFormType, AbstractIcon, abstractTypes } from 'shared/model/def/abstract.def';
import { Abstract, AbstractAuthor, Thematique } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  abstract: Partial<Abstract>;
  hideInformations?: boolean;
  hideAuthors?: boolean;
  disabled?: boolean;
  onSubmit: (abstract: AbstractFormType) => void;
};

const AbstractForm = ({
  abstract,
  hideInformations = false,
  hideAuthors = false,
  disabled = false,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resolver = useAbstractResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AbstractFormType>({
    defaultValues: {
      ...abstract,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const titleI18n = abstract.id ? 'abstract.edit' : 'abstract.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleI18n} icon={<AbstractIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Scrollbars autoHide autoHeight autoHeightMax="calc(100vh - 160px)">
            {!hideInformations && (
              <>
                <Box mb={2} mt={1}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: t('form.errors.required') }}
                    disabled={disabled}
                    render={({ field }) => (
                      <TextField
                        label={t('name')}
                        {...field}
                        required
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControl fullWidth required>
                          <InputLabel>{t('type')}</InputLabel>
                          <Select
                            label={t('type')}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            disabled={disabled}
                          >
                            {abstractTypes.map(type => (
                              <MenuItem key={type} value={type}>
                                {t(`searchFacet.abstract.${type}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    name="thematique"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ThematiqueAutocomplete
                          value={value as Thematique | null}
                          onChange={onChange}
                          disabled={disabled}
                          required
                          error={!!errors.thematique}
                          helperText={errors.thematique?.message}
                        />
                      );
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    name="reference"
                    control={control}
                    disabled={disabled}
                    render={({ field }) => {
                      return (
                        <TextField
                          label={t('abstract.reference')}
                          {...field}
                          error={!!errors.reference}
                          helperText={errors.reference?.message}
                        />
                      );
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    name="theme"
                    control={control}
                    disabled={disabled}
                    render={({ field }) => {
                      return (
                        <TextField
                          label={t('abstract.theme')}
                          {...field}
                          required
                          error={!!errors.theme}
                          helperText={errors.theme?.message}
                        />
                      );
                    }}
                  />
                </Box>
              </>
            )}
            {!hideAuthors && (
              <Box mb={2}>
                <Controller
                  name="authors"
                  control={control}
                  render={({ field }) => {
                    const onChange = (authors: Partial<AbstractAuthor>[]) => {
                      authors.forEach(a => (a.abstract = abstract.id));
                      field.onChange(authors);
                    };

                    return (
                      <>
                        {hideAuthors && (
                          <Box>
                            <Typography variant="h6">{t('abstract.authors')}</Typography>
                          </Box>
                        )}
                        <AbstractEditableAuthors
                          {...field}
                          onChange={onChange}
                          disabled={disabled}
                        />
                      </>
                    );
                  }}
                />
              </Box>
            )}
          </Scrollbars>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default AbstractForm;
