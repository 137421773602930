import { SearchResponse } from '@elastic/elasticsearch/lib/api/types';
import { Box, Pagination } from '@mui/material';
import SearchFacetBtn from 'modules/search/results/SearchFacetBtn';
import SearchIndexFilterBtn from 'modules/search/results/SearchIndexFilterBtn';
import { useGetSearchFacets, useGetSearchText } from 'modules/search/searchHook';
import { useTranslation } from 'react-i18next';
import { setFrom } from 'shared/reducers/searchSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

type Props = {
  response?: SearchResponse<any>;
  isLoading: boolean;
};

const SearchResultBar = ({ response, isLoading }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const indexFilter = useAppSelector(state => state.search.indexFilter);
  const size = useAppSelector(state => state.search.size);
  const from = useAppSelector(state => state.search.from);

  const text = useGetSearchText();
  const facets = useGetSearchFacets();

  if (!response) {
    return null;
  }

  const currentPage = Math.floor(from / size) + 1;

  const metadata = response.hits;
  let total = 0;
  if (typeof metadata.total === 'object') {
    total = metadata.total.value;
  } else {
    total = metadata.total ?? 0;
  }

  const countPageSize = Math.floor(total / size);

  const loadMore = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    dispatch(setFrom((pageNumber - 1) * size));
  };

  return (
    <Box
      py={1.3}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '0.9rem',
        borderBottom: '1px solid',
        borderColor: 'grey.400',
      }}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box mr={1}>{t('searchPage.yourSelection')}</Box>
        <Box>{text}</Box>

        {indexFilter && (
          <>
            <Box ml={1}>et</Box>
            <Box ml={0.4}>
              <SearchIndexFilterBtn icon={false} variant="text" />
            </Box>
          </>
        )}

        {facets.map((f, index) => (
          <Box key={index} display="flex" alignItems="baseline">
            <Box ml={0.4}>et</Box>
            <SearchFacetBtn facet={f} variant="text" icon={false} />
          </Box>
        ))}
      </Box>

      <Box display="flex" whiteSpace="nowrap" alignItems="center">
        <Box>{t('searchPage.nbResults', { nb: total })}</Box>
        <Box ml={1} mr={2}>
          - {response.took} ms
        </Box>
        <Pagination
          count={countPageSize}
          color="primary"
          page={currentPage}
          onChange={loadMore}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
};

export default SearchResultBar;
