import { useTranslate } from '@tolgee/react';
import PersonCreationForm from 'modules/sheet/person/PersonCreationForm';
import { useNavigate } from 'react-router-dom';
import { useCreatePersonMutation } from 'services/endpoints/PersonEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import ModelIcon from 'shared/model/def/ModelIcon';
import { PersonFormType } from 'shared/model/def/person.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const CreatePersonBtn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [createItem, { isLoading }] = useCreatePersonMutation();

  const onSubmit = async (newPerson: PersonFormType) => {
    const saveItem = await createItem({
      item: newPerson,
    }).unwrap();

    navigate(`/sheet/person/${saveItem.id}/main`);

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton type="menuItem" text={t('person.person')} icon={<ModelIcon model="person" />}>
      <PersonCreationForm
        item={{
          civilite: 'mr',
          firstName: '',
          lastName: '',
        }}
        onSubmit={onSubmit}
        disabled={isLoading}
      />
    </OpenDrawerButton>
  );
};

export default CreatePersonBtn;
