import { Box } from '@mui/material';
import { useGetAbstract } from 'modules/sheet/sheetHook';

import { PDFViewer } from '@react-pdf/renderer';
import AbstractPDF from 'modules/sheet/abstract/pdf/AbstractPDF';

const AbstractPDFTab = () => {
  const { data } = useGetAbstract();

  if (!data) {
    return null;
  }

  return (
    <Box>
      <PDFViewer style={{ width: '100%', height: 'calc(100vh - 300px)' }}>
        <AbstractPDF abstract={data} />
      </PDFViewer>
    </Box>
  );
};

export default AbstractPDFTab;
