import { LinkProps } from '@mui/material/Link';
import { frFR as corefrFR } from '@mui/material/locale';
import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';
import { frFR as datePickerfrFR } from '@mui/x-date-pickers/locales';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LinkBehavior } from 'shared/components/EMLink';
import { useCurrentLanguage } from 'shared/hooks/auth-hook';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    muted: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    muted?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    muted: true;
  }
}

const themeOptions: ThemeOptions = {
  //spacing: 8,
  palette: {
    primary: {
      main: '#3f6eb5',
    },
    secondary: {
      main: '#f50057',
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 48,
          minHeight: 48,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  typography: {
    muted: {
      color: '#bbb',
      fontSize: 'small',
    },
  },
};
export const defaultTheme = createTheme({
  ...themeOptions,
  cssVariables: true,
});
export const useLocalizedTheme = () => {
  const lang = useCurrentLanguage();

  if (lang === 'fr') {
    return createTheme(
      {
        ...themeOptions,
        cssVariables: true,
      },
      datePickerfrFR,
      corefrFR,
    );
  }
  return defaultTheme;
};
