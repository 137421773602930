import Stepper from '@mui/material/Stepper';
import { useTranslate } from '@tolgee/react';
import PersonJobStep from 'modules/sheet/person/follow/PersonJobStep';
import EditableCard from 'shared/components/Card/EditableCard';
import { Person } from 'shared/model/types';

type Props = {
  person: Person;
};

const PersonJobStepper = ({ person }: Props) => {
  const { t } = useTranslate();
  const jobs = person.jobs ? [...person.jobs] : [];

  jobs.sort((a, b) => {
    return b.sort - a.sort;
  });

  return (
    <EditableCard title={t('person.parcours')}>
      <Stepper orientation="vertical">
        {jobs.map(job => (
          <PersonJobStep job={job} key={job.id} />
        ))}
      </Stepper>
    </EditableCard>
  );
};

export default PersonJobStepper;
