import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Grid2 as Grid } from '@mui/material';
import AbstractTypesTable from 'modules/sheet/abstract/AbstractTypesTable';
import Scrollbars from 'react-custom-scrollbars';
import { useNavigate } from 'react-router-dom';
import DisplayHtml from 'shared/components/DisplayHtml';
import IconValue from 'shared/components/IconValue';
import { ThematiqueIcon } from 'shared/model/def/thematique.def';
import { Abstract, AbstractAuthor, Person, Thematique } from 'shared/model/types';
import { disablePropagation } from 'shared/utils/event-utils';
import { stringHtmlHasContent } from 'shared/utils/string-utils';

type Props = {
  abstract: Abstract;
  noDescription?: boolean;
};

const SearchResultAbstract = ({ abstract, noDescription = false }: Props) => {
  const navigate = useNavigate();
  const md = noDescription ? 12 : 6;
  const lg = noDescription ? 12 : 4;
  const xl = noDescription ? 12 : 4;

  const authors = abstract.authors ?? [];
  const speaker = authors.find(a => a.speaker) as AbstractAuthor | undefined;
  const speakerPersonId = (speaker?.person as Person)?.id;

  const thematique = abstract.thematique as Thematique | undefined;

  const onSpeakerClick = () => {
    if (speakerPersonId) {
      navigate(`/sheet/person/${speakerPersonId}/main`);
    }
  };

  return (
    <Grid container alignItems="flex-start" spacing={1} fontSize="0.9rem">
      <Grid
        size={{
          xs: 12,
          md: md,
          lg: lg,
          xl: xl,
        }}
      >
        {abstract.theme && (
          <IconValue icon={<CategoryIcon fontSize="small" />}>
            <Box>{abstract.theme}</Box>
          </IconValue>
        )}
        {speaker && (
          <IconValue icon={<PersonIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <Box
                onClick={onSpeakerClick}
                sx={{
                  cursor: speakerPersonId ? 'pointer' : undefined,
                  color: speakerPersonId ? 'primary.main' : undefined,
                }}
              >
                {speaker.firstName} {speaker.lastName}
              </Box>
            </Box>
          </IconValue>
        )}
        {thematique && (
          <IconValue icon={<ThematiqueIcon fontSize="small" />}>
            <Box>{thematique.name}</Box>
          </IconValue>
        )}
        <Box mt={2}>
          <AbstractTypesTable abstract={abstract} />
        </Box>
      </Grid>
      {!noDescription && (
        <Grid
          size={{
            xs: 12,
            md: 6,
            lg: 8,
            xl: 8,
          }}
        >
          {abstract.content && stringHtmlHasContent(abstract.content) && (
            <Scrollbars autoHide autoHeight autoHeightMax="260px">
              <DisplayHtml html={abstract.content} />
            </Scrollbars>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchResultAbstract;
